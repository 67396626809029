import React, {useState} from "react"
import { IFormableProps } from "../libs/formhandling/IFormableProps"
import TimeField from "../libs/TimeField"

const TimeInput = (props: IFormableProps & {
        placeholderText?: string,
        isRequired?: boolean,
        validateOnChange?: boolean,
        lastInputField?: boolean,
        valueGetter: (model: any) => any
        valueSetter: (model: any, value: any) => any
    }
) => {
    const formState = props.formState.use()
    const model = props.formModel as any
    const errors = model.getErrorsFor(props.property)

    return <div className={`plain-input ${props.lastInputField ? 'last-input-field' : ''}`}>
        <TimeField
            value={props.valueGetter(model)}
            showSeconds={false}
            onChange={(e)=>{
                props.valueSetter(model, e.target.value)
                formState.triggerUpdate()
            }}
        />
        {errors &&
        <div className={'invalid-feedback error-message'}>{errors[0]}</div>
        }
    </div>

}

export {TimeInput}
