import { SubscriptionState } from "../libs/statemanagement/SubscriptionState";
import { updatesSubscribers } from "../libs/statemanagement/decorators/updatesSubscribers";
import {StateModel} from "../libs/statemanagement/StateModel";
import {dispatches} from "../libs/statemanagement/decorators/dispatches";

export class BodyClassesState extends SubscriptionState {

    static instance = new BodyClassesState()

    value: {[id:string]: any} = {}

    @updatesSubscribers()
    addClass(value: string) {
        this.value[value] = true
    }

    @updatesSubscribers()
    removeClass(value: string) {
        delete this.value[value]
    }

}