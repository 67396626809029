import React, {useEffect} from "react"
import {Configure, InstantSearch} from "react-instantsearch-dom"
import {SearchClient} from "../../algolia/SearchClient"
import {appEnv} from "../../../env"
import {PlaceByAddressSearchBar} from "./PlaceByAddressSearchBar"
import {PlaceHits} from "./PlaceHits"
import {PlaceFacets} from "./PlaceFacets"
import {PlaceSearchState} from "../../state/PlaceSearchState"
import {PlaceStats} from "./PlaceSearchStats"
import {AdditionalSearchState} from "../../state/AdditionalSearchState"
import {PlaceSearchPagination} from "./PlaceSearchPagination"
import {MobileSearchFilterState} from "../../state/MobileSearchFilterState"
import classNames from "classnames"
import {PlaceSearchMap} from "./PlaceSearchMap"
import {NavigationHistoryStore} from "../../utils/NavigationHistoryStore"
import {LocalStorageRepo} from "../../utils/LocalStorageRepo"

const PlaceSearchIndex: React.FC = () => {

    const searchState = PlaceSearchState.instance.use()
    const mobileSearchFilterState = MobileSearchFilterState.instance.use()

    const restoreStateFromLocalStorage = () => {
        const stateToRestore = LocalStorageRepo.getPlaceSearchState() ?? {}
        searchState.onSearchStateChange(stateToRestore)
    }

    useEffect(() => {
        if (NavigationHistoryStore.isNavigatedBackToPlaceSearch()) {
            if (!searchState.initialQuery) {
                restoreStateFromLocalStorage()
            }
        }
        return () => {
            LocalStorageRepo.putPlaceSearchState(searchState.searchState)
            PlaceSearchState.instance.cleanup()
            AdditionalSearchState.instance.cleanup()
        }
    }, [])

    return <div
        className={classNames(
            'main-bg',
            {
                'lock': mobileSearchFilterState.isActive
            }
        )}
    >
        <InstantSearch
            searchClient={SearchClient.get()}
            indexName={appEnv.ALGOLIA_PLACE_INDEX}
            searchState={searchState.searchState}
            onSearchStateChange={searchState.onSearchStateChange}
        >
            <Configure
                filters={searchState.getFilters()}
                hitsPerPage={50}
                maxValuesPerFacet={200}
            />
            <div className={'result-search'}>
                <div className={"container"}>
                    <div className={'results-wrapper'}>
                        <PlaceStats/>
                        <PlaceByAddressSearchBar/>
                    </div>
                </div>
            </div>

            <div className={"listing"}>
                <div className={"container"}>
                    <PlaceFacets/>
                    <div className={"listing-results"}>
                        <PlaceSearchMap/>
                        <PlaceHits/>
                        <PlaceByAddressSearchBar/>
                        <div className={'flex-full-width'}>
                            <PlaceStats/>
                            <PlaceSearchPagination/>
                        </div>
                    </div>
                </div>
            </div>
        </InstantSearch>
    </div>

}

export {PlaceSearchIndex}