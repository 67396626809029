import React, {useMemo} from "react"
import {getCategoriesForSelect, NewPLaceFormState} from "../../formstates/NewPLaceFormState"
import {PlainInput} from "../../formelements/PlainInput"
import {asKeyOf} from "../../libs/typeutils/asKeyOf"
import {Place} from "../../model/Place"
import {MediaUploadInput} from "../../formelements/MediaUploadInput"
import {TextAreaInput} from "../../formelements/TextAreaInput"
import {OpenTimeEditBlock} from "../../opentime/OpenTimesEditBlock"
import {MultipleSelect} from "../../formelements/MultipleSelect"
import {MultiCheckboxGroupInput} from "../../formelements/MultiCheckboxGroupInput"
import {Address} from "../../model/Address"
import {ValueBasedCheckbox} from "../../formelements/ValueBasedCheckbox"
import {GeneralErrorsContainer} from "../../formelements/GeneralErrorsContainer"
import Loader from "react-loader-spinner"
import {PlaceEditFormState} from "../../formstates/PlaceEditFormState"
import {Media} from "../../model/Media"

const PlaceEdit: React.FC<{
    placeId: number
}> = ({placeId}) => {

    const formState = useMemo(() => {
        return PlaceEditFormState.initForEdit(placeId)
    }, []).use();

    if (!formState.isLoaded()) {
        return <h1>IS LOADING...</h1>
    }

    const place = formState.model
    const openTimes = place.openTimes
    const address = place.address!

    return <div className={"main-form"}>
        <div className={'container'}>
            <div className={"form-headline-row"}>
                <p className={'form-title'}>Foodlover Daten bearbeiten</p>
            </div>
            <div className={'form-card'}>
                <p className={'card-title'}>Basis-Infos über dein Restaurant</p>
                <PlainInput type={'text'}
                            property={asKeyOf<Place>('name')}
                            formState={formState}
                            formModel={place}
                            validateFun={() => {
                                formState.validatePlaceName(place)
                            }}
                            isRequired={true}
                            labelText={'Restaurantname*'}
                            placeholderText={"Wie heißt dein Restaurant?"}
                />
                <MediaUploadInput formState={formState}
                                  formModel={place.logoMedia!}
                                  property={'file'}
                                  labelText={'Restaurantlogo'}
                                  placeholder={'JPG-,GIF- oder PNG-Datei'}
                                  validate={() => {
                                      formState.validatePlaceLogoMedia(place.logoMedia!)
                                  }}
                                  showFileName={(model: Media)=>{return model.originalFileName}}
                />
                <MediaUploadInput formState={formState}
                                  formModel={place.imageMedia!}
                                  property={'file'}
                                  labelText={'Restaurantbild'}
                                  placeholder={'Ein Bild Ihres Restaurants'}
                                  validate={() => {
                                      formState.validatePlaceImageMedia(place.imageMedia!)
                                  }}
                                  showFileName={(model: Media)=>{return model.originalFileName}}
                />
                <TextAreaInput
                    formState={formState}
                    formModel={place}
                    property={asKeyOf<Place>('placeDescription')}
                    labelText={'Restaurantbeschreibung'}
                    placeholderText={'Beschreibe dein Restaurant mit ein paar Worten.'}
                />

                <div className={"calendar-select"}>
                    <p>Öffnungszeiten*</p>
                    <OpenTimeEditBlock
                        formState={formState}
                        openTimes={openTimes}
                    />
                </div>

                <div className={'single-select'}>
                    <MultipleSelect
                        formState={formState}
                        formModel={place}
                        labelString={"Küchenkonzept*"}
                        property={asKeyOf<Place>("categories")}
                        selectPairs={formState.categoriesSelectPairs}
                        placeHolder={"Welche Küche bietest du an? (siehe Auswahlmenü)"}
                        valueAdder={(value) => {
                            formState.addCategoryFromModelValue(place, value)
                        }}
                        valueRemover={(value) => {
                            formState.removeCategoryFromModelValue(place, value)
                        }}
                        isSelected={(value) => {
                            return formState.isCategorySelectedFromModelValue(place, value)
                        }}
                        errors={place.getErrorsFor(asKeyOf<Place>('categories')) ?? undefined}
                    />
                </div>

            </div>


            <div className={'form-card'}>
                <p className={'card-title'}>Wichtige Service-Infos</p>

                <div className={"checkbox-row"}>

                    <div className={"checkbox-row"}>
                        <div className={'row-wrapper orderoptions'}>
                            <MultiCheckboxGroupInput
                                formState={formState}
                                formModel={place}
                                property={asKeyOf<Place>("orderOptions")}
                                placeHolder={"Bestelloptionen*"}
                                selectPairs={formState.orderOptionsForSelect}
                                elementAdder={formState.addOrderOption as any}
                                elementRemover={formState.removeOrderOption as any}
                                isSelected={formState.isOrderOptionSelected as any}
                                isValid={formState.orderOptionIsValid as any}
                                showErrors={!!place.getErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)}
                            />
                            {place.getErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)
                            && <div className={"error"}>
                                {place.getErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)?.map((it) => {
                                    return <p>{it}</p>
                                })}
                            </div>
                            }
                        </div>

                    </div>

                    <MediaUploadInput formState={formState}
                                      formModel={place.menuMedia!}
                                      property={'file'}
                                      labelText={'Speisekarten Downloadmöglichkeit (PDF)'}
                                      placeholder={'Hier kannst Du deine Speisekarte hochladen'}
                                      validate={() => {
                                          formState.validatePlaceMenuMedia(place.menuMedia!)
                                      }}
                                      showFileName={(model: Media)=>{return model.originalFileName}}
                    />

                    <div className={'checkbox-row'}>
                        <div className={'row-wrapper'}>
                            <MultiCheckboxGroupInput
                                formState={formState}
                                formModel={place}
                                property={asKeyOf<Place>("paymentTypes")}
                                placeHolder={"Zahlarten*"}
                                selectPairs={formState.paymentTypesForSelect}
                                elementAdder={formState.addPaymentType as any}
                                elementRemover={formState.removePaymentType as any}
                                isSelected={formState.isPaymentTypeSelected}
                                showErrors={false}
                                isValid={() => true}
                            />
                            {place.getErrorsFor(NewPLaceFormState.PAYMENT_TYPE_SELECTION)
                            && <div className={"error"}>
                                {place.getErrorsFor(NewPLaceFormState.PAYMENT_TYPE_SELECTION)?.map((it) => {
                                    return <p>{it}</p>
                                })}
                            </div>
                            }
                        </div>
                    </div>
                    <TextAreaInput
                        formState={formState}
                        formModel={place}
                        property={asKeyOf<Place>('additionalInformation')}
                        isRequired={false}
                        labelText={'Sonstige Infos'}
                        placeholderText={"Bitte trage hier weitere, gewünschte Infos ein"}
                    />

                </div>
            </div>
            <div className={'form-card contact-data'}>
                <p className={'card-title'}>Ihre Kontaktdaten</p>
                <div className={'row inline-xs'}>
                    <div className={'col-7 col-md-9'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('street')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressStreet(address)
                                    }}
                                    labelText={'Straße*'}
                                    placeholderText={"Hier bitte den Straßenname angeben"}
                        />
                    </div>
                    <div className={'col-5 col-md-3'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>("number")}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Hausnummer*'}
                                    placeholderText={"… und die Nr."}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressNumber(address)
                                    }}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-4'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>("zipCode")}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Postleitzahl*'}
                                    placeholderText={"Bitte die PLZ angeben"}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressZipCode(address)
                                    }}
                        />
                    </div>
                    <div className={'col-12 col-md-8'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('city')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Ort*'}
                                    placeholderText={"Wo befindet sich ihr Restaurant?"}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressCity(address)
                                    }}
                        />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('phone')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Telefonnummer*'}
                                    placeholderText={"Telefonnummer"}
                                    validateFun={() => {
                                        formState.validateAddressPhoneNumber(address)
                                    }}
                        />
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('email')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'E-Mail*'}
                                    placeholderText={"Deine E-Mail-Adresse"}
                                    validateFun={() => {
                                        formState.validateAddressEmail(address)
                                    }}
                        />
                    </div>
                </div>


                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('website')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'Website'}
                                    placeholderText={"Wie lautet deine Websiteadresse?"}
                                    validateFun={() => {
                                        formState.validateWebsite(address)
                                    }}
                        />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('socialMediaAddress1')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'Facebook'}
                                    placeholderText={"Social-Media-Adresse"}
                        />
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('socialMediaAddress2')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'Instagram'}
                                    placeholderText={"Social-Media-Adresse"}
                        />
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <GeneralErrorsContainer
                            errors={place.getErrorsFor('general')}
                        />
                    </div>
                    <div className={"row last submit-row"}>
                        <span className={'note-necessary'}>*verpflichtende Angaben</span>
                        <div
                            className={'btn filled-white'}
                            onClick={() => {
                                formState.update()
                            }}
                        >
                            <span>Aktualisieren</span>
                            {formState.isLoading &&
                                <Loader height={25} width={25} color={'#ccc'} type={'ThreeDots'}/>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

}

export {PlaceEdit}