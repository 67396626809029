// @ts-ignore
import {GoogleMapsLoader, GeoSearch, Control, Marker, CustomMarker} from 'react-instantsearch-dom-maps'
import React, {useRef} from "react"
import {appEnv} from "../../../env"
import {PlaceSearchMarker} from "./PlaceSearchMarker"
import {PlaceSearchMapNoResults} from "./PlaceSearchMapNoResults"

const PlaceSearchMap: React.FC = () => {

    let mapRef = useRef()

    return <div className="place-search-map">
        <PlaceSearchMapNoResults/>
        <div className={'mapoverlay'}>
            Bewege oder zoom die Karte zum Suchen.
        </div>
        <GoogleMapsLoader apiKey={appEnv.MAPS_API_KEY}>
            {(google: any) => {
                mapRef.current = google
                return <GeoSearch
                    google={google}
                    initialPosition={{lat: 51.1642292, lng: 10.4541194}}
                    initialZoom={10}
                >
                    {({hits}: any) => {
                        let markers = hits?.map((hit: any) => {
                            return <CustomMarker
                                key={hit.objectID}
                                hit={hit}
                            >
                                <PlaceSearchMarker hit={hit}/>
                            </CustomMarker>
                        })
                        return <div>
                            {markers}
                        </div>
                    }}
                </GeoSearch>
            }}
        </GoogleMapsLoader>
    </div>
}

export {PlaceSearchMap}