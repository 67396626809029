import {ApiEndpoint, BaseModel, Property, RequestOptions} from "../libs/frontmodel/src";

export class Address extends BaseModel {

    @Property
    id?: number

    @Property
    zipCode?: string

    @Property
    city?: string

    @Property
    phone?: string

    @Property
    website?: string

    @Property
    liferandoLink?: string

    @Property
    socialMediaAddress1?: string

    @Property
    socialMediaAddress2?: string

    @Property
    placeId?: number

    @Property
    number?: number

    @Property
    street?: string

    @Property
    lat?: number

    @Property
    lng?: number

    @Property
    email?: string

    @ApiEndpoint("GET", {url: "/api/address/email/doesNotExist"})
    static emailAlreadyExists: (options: RequestOptions) => Promise<Address>

    getFacebookLink = () => {
        return getSocialMediaAddressContaining(this, 'facebook')
    }

    getInstagramLink = () => {
        return getSocialMediaAddressContaining(this, 'instagram')
    }

    getWebsite = () => {
        let website = this.website
        if (!website) {
            return website
        }
        if (website.startsWith('http')) {
            return website
        }
        return `http://${website}`
    }

}

const getSocialMediaAddressContaining = (address: Address, searchString: string) => {
    let socialOne = address.socialMediaAddress1?.toLowerCase()
    if (socialOne && socialOne?.toLowerCase()?.includes(searchString)) {
        return socialOne
    }
    let socialTwo = address.socialMediaAddress2
    if (socialTwo && socialTwo?.toLowerCase()?.includes(searchString)) {
        return socialTwo
    }
    return undefined
}