import {ApiEndpoint, BaseModel, ModelCollection, Property, RequestOptions} from "../libs/frontmodel/src"

export class Category extends BaseModel {

    @Property
    id?: number

    @Property
    name?: string

    @Property
    systemName?: string

    @ApiEndpoint("GET", {url: `/api/categories`})
    static index: (options?: RequestOptions) => Promise<ModelCollection<Category>>

}