import React, {useDebugValue} from "react"
import {FormState} from "../libs/formhandling/FormState"
import {ModelCollection} from "../libs/frontmodel/src"
import {OpenTime} from "../model/OpenTime"
import {ValueBasedCheckbox} from "../formelements/ValueBasedCheckbox"
import {asKeyOf} from "../libs/typeutils/asKeyOf"
import {TimeInput} from "../formelements/TimeInput"
import {OpenTimeEditRow} from "./OpenTimeEditRow"
import {NewPLaceFormState} from "../formstates/NewPLaceFormState"

const OpenTimeEditBlock: React.FC<{
    formState: NewPLaceFormState,
    openTimes: ModelCollection<OpenTime>
}> = ({formState, openTimes}) => {

    formState.use()

    return <div className={'calendar-wrapper'}>
        {openTimes.map((openTime)=>{
            return <OpenTimeEditRow
                formState={formState}
                openTime={openTime}
                key={openTime.day}
            />
        })}
    </div>
}

export {OpenTimeEditBlock}