import React, {createRef} from "react";
import {IFormableProps} from "../libs/formhandling/IFormableProps";
import classNames from "classnames";
import {ISelectPair} from "./SingleSelectInput";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import {BaseModel} from "../libs/frontmodel/src";

export interface IMiniCheckboxGroupSelect {
    image?: any,
    placeHolder: string,
    value: BaseModel
}

const MultiCheckboxGroupInput: React.FC<
IFormableProps & {
    placeHolder: string
    labelString?: string
    selectPairs: IMiniCheckboxGroupSelect[]
    elementAdder: (model: any, value: any) => any,
    elementRemover: (model: any, value: any) => any,
    isSelected: (model: any, value: any) => any,
    className?: string,
    classNameInputFieldWrapper?: string
    showErrors: boolean
    isValid: (model: any, value: any) => any
}
> = (props) => {

    const formState = props.formState.use()
    const model = props.formModel as any
    const selectPairs = props.selectPairs

    return <div className={classNames(props.className, 'checkbox-select-group')}>
        <div className="placeholder f-item">
            <p>{props.placeHolder}</p>
        </div>

        {selectPairs.map((it)=>{
            let isChecked = props.isSelected(model, it.value)
            let hasErrors = props.showErrors && !props.isValid(model, it.value)

            return <div className={"checkbox-element"} key={it.placeHolder}>
                <span className={`select-box-item boxed${isChecked ? ' selected' : ''}${hasErrors ? ' error':''} `}
                      onClick={(e) => {
                          isChecked
                              ? props.elementRemover(model, it.value)
                              : props.elementAdder(model, it.value)

                          formState.triggerUpdate()
                      }}
                >
                    {it.placeHolder && !it.image &&
                    <span>
                            {it.placeHolder}
                        </span>
                    }
                    {it.image &&
                        <span className={'icon'}>
                            <img src={it.image}/>
                        </span>
                    }
                </span>

            </div>
        })}
    </div>
}

export {MultiCheckboxGroupInput}