import React from "react"
import { IFormableProps } from "../libs/formhandling/IFormableProps"
import {BaseModel} from "../libs/frontmodel/src"


const TextAreaInput: React.FC<IFormableProps & {
	labelText?: string,
	placeholderText?: string,
	isRequired?: boolean,
	validateOnChange?: boolean
}> = (props) => {

	const formState = props.formState.use()
	const model = props.formModel as any | BaseModel
	const error = model.getFirstErrorFor(props.property)
	const isValid = !error ? 'is-valid' : 'is-invalid'
	const inputType =  "textarea"
	const placeHolder = props.placeholderText
	const isRequired = props.isRequired

	return <div className={`plain-input ${inputType}`}>
		<label>{props.labelText}</label>
		<textarea
			value={model[props.property]}
			className={`${isValid} form-control`}
			required={isRequired}
			onChange={(e)=>{
				model[props.property] = e.target.value
				if (props.validateOnChange) {
					if (props.validateFun) {
						props.validateFun()
					} else {
						formState.validate()
					}
				}
				formState.triggerUpdate()
			}}
		/>
		{placeHolder &&
			<span className={`placeholder`}>{props.placeholderText}</span>
		}
		{error &&
			<div className={'invalid-feedback error-message'}>{error}</div>
		}
	</div>

}

export {TextAreaInput}
