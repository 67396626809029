import {createBrowserHistory} from "history";
import {PlaceSearchState} from "../../state/PlaceSearchState"


const AppHistory = createBrowserHistory();

export class RouterNavigationUtils {

    static history = AppHistory

    static pushToHome() {
        this.history.push('/')
    }

    static pushToError() {
        this.history.push("/error")
    }

    static pushToNewPlaceSuccess() {
        this.history.push("/success")
    }

    static navigateToSearch(query: string) {
        PlaceSearchState.instance.initialQuery = query
        this.history.push('/places')
    }

    static pushToPlaceShow(placeId: number) {
        this.history.push(`/places/${placeId}`)
    }

    static pushToPlaceEdit(placeId: number) {
        console.log("pushing")
        this.history.push(`/places/edit/${placeId}`)
    }
}