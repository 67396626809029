import {ApiEndpoint, BaseModel, HasMany, ModelCollection, Property, RequestOptions} from "../libs/frontmodel/src"
import {Place} from "./Place"

export class User extends BaseModel {

    @Property
    id?: number

    @HasMany("Place")
    places!: ModelCollection<Place>

    @ApiEndpoint("GET", {url: "/api/session/loginViaLink"})
    static loginViaLink: (options: RequestOptions) => Promise<User>

    static afterLoginViaLinkRequest(options: RequestOptions) {
        return this.afterShowRequest(options)
    }

}