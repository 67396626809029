import React from "react";

const BlogArticle = (props: {
    image: string,
    overline?: string,
    headline: string,
    date?: string,
    url: string
}) => {
    return <div className={'blog-article'}>
        <a href={props.url} target={'_blank'}>
            <img className={'blogimage'} src={props.image}/>
        </a>
        <div className={"blog-inner-wrapper"}>
            {props.overline &&
            <span className={'overline'}>{props.overline}</span>
            }
            <h3>{props.headline}</h3>
        </div>
        <a href={props.url} target={'_blank'} className={'link-to-block'}>
            <div className={'row-date'}>
                {props.date &&
                <span className={'publish-date'}>{props.date}</span>
                }
                <svg xmlns="http://www.w3.org/2000/svg" width={9} height={12} viewBox="0 0 9 12">
                    <path d="M312 288L318.043 282.108 312 276 314.826 276 321 282.01 314.826 288z"
                          transform="translate(-312 -276)"/>
                </svg>

            </div>
        </a>
    </div>
}
export {BlogArticle}