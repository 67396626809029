import React from "react"
import GoogleMapReact from 'google-map-react'
import {Address} from "../../model/Address"
import {PlaceMarker} from "../layouts/layoutelements/map/PlaceMarker"
import {appEnv} from "../../../env"

const PlaceMiniMap: React.FC<{
    address: Address,
    additionalClass?: boolean
}> = ({address,additionalClass}) => {

    const defaultCenter = {lat: address.lat!, lng: address.lng!}
    const defaultZoom = 14

    if (!address.lat) {
        return null
    }

    return <div className={`gmaps ${additionalClass ? 'mobile-maps' : ''}`}>
        <GoogleMapReact
            bootstrapURLKeys={{key: appEnv.MAPS_API_KEY}}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
        >
            <PlaceMarker lat={address.lat!} lng={address.lng!}/>
        </GoogleMapReact>
    </div>

}

export {PlaceMiniMap}