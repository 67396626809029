import {IFormableProps} from "../libs/formhandling/IFormableProps"
import {Simulate} from "react-dom/test-utils"
import React, {useRef} from "react"
import input = Simulate.input;

const MediaUploadInput: React.FC<IFormableProps & {
    labelText: string,
    placeholder?: string,
    validate: () => any,
    onChange?: (model: any, value: File|undefined) => any,
    showFileName?: (model: any) => string|undefined
}> = (props) => {

    const formState = props.formState.use()
    const fileInput = useRef<HTMLInputElement>(null)

    const model = props.formModel as any
    const errors = model.getErrorsFor(props.property)

    const onChange = () => {
        const file = fileInput.current!.files?.[0] as File || undefined
        if (file) {
            model[props.property] = file
        }
        props.onChange?.(model, file)
        props.validate()
        formState.triggerUpdate()
    }

    return <div className={'upload-field'}>
        <p>{props.labelText}</p>
        <div className={'row'}>
            <label htmlFor={props.formModel.getReactKey() as any}>
                <div className={'upload-file'}>
                    <div className={'pseudo-input'}>{props.placeholder}</div>
                </div>
            </label>
            <div className={'btn black-filled upload'}
                onClick={()=>{
                    fileInput?.current?.click()
                }}
            >Hochladen</div>
        </div>
        <input type="file" id={props.formModel.getReactKey() as any} ref={fileInput}
               onChange={onChange}
        />
        {model[props.property]
            ? <div>
                {(model[props.property] as File).name}
            </div>
            : (
                props.showFileName?.(model)
                && <div>
                    {props.showFileName?.(model)}
                </div>
            )
        }
        {errors &&
            <div className={'errors'}>
                {errors?.map((it: string)=>{
                    return <div className={"error"} key={it}>
                        {it}
                    </div>
                })}
            </div>
        }
    </div>

}

export {MediaUploadInput}