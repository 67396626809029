import React from "react";
import {Place} from "../../../../model/Place";
import {ModelCollection} from "../../../../libs/frontmodel/src";
import {Media} from "../../../../model/Media";

const DetailDownload:React.FC<{
    place: Place,
    additionalClass?: boolean
}> = ({place,additionalClass}) =>{

    return <div className={`grid-download ${additionalClass ? 'mobile-download' : ''}`}>
        <div className={'download-text'}>
            <h3>Speisekartendownload</h3>
            <p>Unsere aktuelle Speisekarte<br/>zum Herunterladen</p>
        </div>
        <a href={`${place.menuMedia?.getSrc()}`} className={'download-button'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11">
                <path fill="#FFF" d="M236 52L242.715 44.635 236 37 239.14 37 246 44.512 239.14 52z" transform="rotate(90 144 -91.5)"/>
            </svg>
        </a>
    </div>
}
export {DetailDownload}