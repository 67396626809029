import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"
import {updatesSubscribers} from "../libs/statemanagement/decorators/updatesSubscribers"

export class CurrentViewportState extends SubscriptionState {

    currentViewport!: "l" | "m" | "s"

    static instance: CurrentViewportState

    private resizeTimeout: any

    static init() {
        this.instance = new CurrentViewportState()
    }

    constructor() {
        super();
        this.setViewportDependingOnWidth()
        window.onresize = this.onResizeListener

    }

    onResizeListener =  () => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout)
        }
        this.resizeTimeout = setTimeout(()=>{
            this.setViewportDependingOnWidth()
            this.resizeTimeout = undefined
        }, 100)
    }

    @updatesSubscribers()
    setViewportDependingOnWidth() {
        const width = this.getWidth()
        if (width > 1259) {
            this.currentViewport = 'l'
            return
        }
        if (width > 767) {
            this.currentViewport = 'm'
            return
        }
        this.currentViewport = 's'
    }

    getWidth() {
        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    }

}