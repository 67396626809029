import React, {useEffect, useMemo} from "react"
import {PlaceShowState} from "../../state/PlaceShowState"
import {LocaleImage} from "../layouts/layoutelements/detail/DetailImages";
import {DetailDescription} from "../layouts/layoutelements/detail/DetailDescription";
import {DetailService} from "../layouts/layoutelements/detail/DetailService";
import Loader from "react-loader-spinner";
import {DetailContactInfo} from "../layouts/layoutelements/detail/DetailContactInfo";
import {DetailCalendar} from "../layouts/layoutelements/detail/DetailCalendar";
import {DetailDownload} from "../layouts/layoutelements/detail/DetailDownload";
import {PlaceMiniMap} from "./PlaceMiniMap"
import {SearchRedirectingSearchBar} from "./SearchReiderctingSearchBar"

const PlaceShow: React.FC<{placeId: string}> = ({placeId}) => {

    const placeState = useMemo(()=>{return new PlaceShowState()}, []).use()

    const place = placeState.place

    useEffect(()=>{
        placeState.load(placeId)
    }, [])


    if (!place) {
        return <div className={'modal-center'}>
            <Loader height={100} width={100} color={'#b40c19'} type={'ThreeDots'}/>
        </div>
    }


    return <div className={'detail-main'}>



        <div className={'container'}>
            <div className={'header-row'}>
                <h1>{place.name}</h1>
                <SearchRedirectingSearchBar/>
            </div>
            <div className={'flex-row images'}>
                <LocaleImage place={place}/>
            </div>

            <section className={'detail-body'}>
                <div className={"left-section"}>
                    <DetailDescription place={place}/>
                    <DetailService place={place}/>
                </div>
                <div className={'right-section'}>
                    <PlaceMiniMap address={place.address!} additionalClass={false}/>
                    <DetailContactInfo place={place}/>
                    <DetailCalendar calendar={place.openTimes} place={place}/>
                    {place?.menuMedia
                        && <DetailDownload place={place} additionalClass={false}/>
                    }
                </div>
                <PlaceMiniMap address={place.address!} additionalClass={true}/>
                {place?.menuMedia
                    && <DetailDownload place={place} additionalClass={true}/>
                }
            </section>
            <SearchRedirectingSearchBar/>
        </div>

    </div>
}

export {PlaceShow}