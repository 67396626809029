import React from "react"
import {FormState} from "../libs/formhandling/FormState"
import {OpenTime} from "../model/OpenTime"
import {ValueBasedCheckbox} from "../formelements/ValueBasedCheckbox"
import {asKeyOf} from "../libs/typeutils/asKeyOf"
import {TimeInput} from "../formelements/TimeInput"
import {NewPLaceFormState} from "../formstates/NewPLaceFormState"

const OpenTimeEditRow: React.FC<{
    formState: NewPLaceFormState,
    openTime: OpenTime
}> = ({formState, openTime}) => {

    formState.use()

    const openThisDay = openTime.openThisDay

    const from = openTime.from
    const to = openTime.to
    const fromExtra = openTime.fromExtra
    const toExtra = openTime.toExtra

    const isShowExtraTime = () => {
        return ![fromExtra ?? true, toExtra ?? true].includes(true)
    }

    const showAdd = () => {
        return (![from ?? false, to ?? false].includes(false)
            && !(from === 0 && to === 0)
            && [fromExtra ?? false, toExtra ?? false].includes(false)
        )
    }

    const remove = (type: "first" | "extra") => {
        if (type === 'first') {
            openTime.from = openTime.fromExtra
            openTime.to = openTime.toExtra
            openTime.toExtra = null
            openTime.fromExtra = null
        }
        if (type === 'extra') {
            openTime.toExtra = null
            openTime.fromExtra = null
        }
        formState.triggerUpdate()
    }

    const add = () => {
        openTime.fromExtra = 0
        openTime.toExtra = 0
        formState.triggerUpdate()
    }

    const translateToMinutes = (value: string) => {
        const [hours, minutes] = value.split(':')
        const totalMinutes = (parseInt(hours) * 60) + parseInt(minutes)
        return totalMinutes
    }

    const translateToString = (value: number) => {
        let hours = `${Math.floor(value / 60)}`
        let minutes = `${value % 60}`
        if (hours.length < 2) {
            hours = `0${hours}`
        }
        if (minutes.length < 2) {
            minutes = `0${minutes}`
        }
        return `${hours}:${minutes}`
    }

    return <div className={'opening-times-row'}>
        <ValueBasedCheckbox
            formState={formState}
            formModel={openTime}
            property={asKeyOf<OpenTime>('openThisDay')}
            valueOnChecked={true}
            valueOnUnchecked={false}
            placeholderText={""}
            onChange={(value) => {
                if (value) {
                    openTime.from = 0
                    openTime.to = 0
                } else {
                    openTime.from = null
                    openTime.to = null
                    openTime.fromExtra = null
                    openTime.toExtra = null
                }
            }}
        />
        <p className={'day'}>{openTime.getHumanReadableName()}</p>
        <p className={'open-close'}>{openTime.openThisDay ? "geöffnet" : "geschlossen"}</p>


        {openThisDay
        && <div className={'start-time-row'}>

                <TimeInput
                    formState={formState}
                    formModel={openTime}
                    property="from"
                    valueGetter={(model: OpenTime) => {
                        return translateToString(model.from!)
                    }}
                    valueSetter={(model: OpenTime, value: any) => {
                        model.from = translateToMinutes(value)
                    }}
                />
                <span className={"separator"}>-</span>
                <TimeInput
                    formState={formState}
                    formModel={openTime}
                    property="to"
                    valueGetter={(model: OpenTime) => {
                        return translateToString(model.to!)
                    }}
                    valueSetter={(model: OpenTime, value: any) => {
                        model.to = translateToMinutes(value)
                    }}
                />
            {isShowExtraTime()
                && <span className={'close-time-row'}
                         onClick={() => {
                             remove("first")
                         }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} viewBox="0 0 8 8">
                        <g fill="none" fillRule="evenodd" stroke="#000">
                        <path d="M0 0l7.517 7.242M.159 7.354L7.432.081" />
                        </g>
                    </svg>
                </span>
            }
        </div>
        }
        {showAdd()
        && <span className={'add-more'}
            onClick={() => {
                add()
            }}
        >
            Weitere Hinzufügen
        </span>
        }

        {isShowExtraTime()
        && <div className={'start-time-row'}>
            <TimeInput
                formState={formState}
                formModel={openTime}
                property={asKeyOf<OpenTime>('fromExtra')}
                valueGetter={(model: OpenTime) => {
                    return translateToString(model.fromExtra!)
                }}
                valueSetter={(model: OpenTime, value: any) => {
                    model.fromExtra = translateToMinutes(value)
                }}
            />
            <span className={"separator"}>-</span>
            <TimeInput
                formState={formState}
                formModel={openTime}
                property={asKeyOf<OpenTime>('toExtra')}
                valueGetter={(model: OpenTime) => {
                    return translateToString(model.toExtra!)
                }}
                valueSetter={(model: OpenTime, value: any) => {
                    model.toExtra = translateToMinutes(value)
                }}
            />
            <span className={'close-time-row'}
                onClick={() => {
                    remove("extra")
                }}
            >
             <svg xmlns="http://www.w3.org/2000/svg" width={8} height={8} viewBox="0 0 8 8">
                    <g fill="none" fillRule="evenodd" stroke="#000">
                    <path d="M0 0l7.517 7.242M.159 7.354L7.432.081" />
                    </g>
                </svg>
            </span>
        </div>
        }
    </div>
}

export {OpenTimeEditRow}