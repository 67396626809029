import React, {useRef, useState} from "react"
import {Place} from "../../model/Place"
import {Link} from "react-router-dom"
import {ClickOutside} from "../../libs/ClickOutside"

const PlaceSearchMarker: React.FC<{
    hit: any
}> = ({hit}) => {

    const [showDetail, setShowDetail] = useState(false)
    const markerRef = useRef<HTMLSpanElement | any>(undefined)

    const toggleParentZIndex = (state: boolean) => {
        const parentElement = markerRef?.current?.parentElement
        if (parentElement) {
            if (state) {
                parentElement.style.zIndex = "900"
            } else {
                parentElement.style.zIndex = "1"
            }
        }
    }

    const triggerActive = (value: boolean) => {
        if (showDetail === value) {
            return
        }
        toggleParentZIndex(value)
        setShowDetail(value)
    }

    let place = new Place(hit)

    return <span
        className={'heart-icon-svg'}
        onClick={() => {
            triggerActive(true)
        }}
        ref={markerRef}
    >
        {showDetail
            ? <ClickOutside on={()=>{
                triggerActive(false)
            }}>
                <div className="place-search-map-marker-detail">
                    <div className={"locale-right-section"}>
                        <span className={"maps-locale-name"}>{place.name}</span>
                        <span className={'maps-locale-description'}> {place.getTruncatedPlaceDescription()}</span>
                        <span className={`maps-locale-opening ${place.isOpenNow()?'':'closed'}`}>
                            {place.isOpenNow()
                                ? "geöffnet"
                                : "geschlossen"
                            }
                        </span>
                    </div>
                    <Link className={'main-button'} to={`/places/${place.id}`}>
                        <div>
                            Zum Restaurant
                        </div>
                    </Link>
                </div>
            </ClickOutside>
            : <div className={'maps-preview'}
                onMouseEnter={() => {
                    toggleParentZIndex(true)
                }}
                onMouseLeave={()=>{
                    toggleParentZIndex(false)
                }}
            >
                < img src={'/public/img/svg/logo-maps.svg'} alt={"Foodlover"}/>
                <span>{place.name}</span>
            </div>
        }
</span>
}

export {PlaceSearchMarker}