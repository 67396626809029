import React from "react"
import { connectPagination } from "react-instantsearch-dom"
import classNames from "classnames"

const Pagination: React.FC<{
    currentRefinement: number,
    nbPages: number,
    refine: (value: number)=>any,
    createURL: ()=>any,
}> = ({currentRefinement, nbPages, refine}) => {

    let end = null
    let start = currentRefinement
    if (start - 2 < 1) {
        start = 1
        if (start + 5 > nbPages) {
            end = nbPages
        } else {
            end = start + 5
        }
    } else {
        if (start + 5 > nbPages) {
            start = nbPages - 5
            if (start < 1) {
                start = 1
            }
            end = nbPages
        } else {
            start = start - 2
            end = start + 5
        }
    }

    const pages = []
    while (start < end + 1) {
        pages.push(start)
        start += 1
    }

    if (nbPages < 2) {
        return null
    }

    return <div className={'pagination'}>

        <span>Seite</span>

        {currentRefinement > 1
            && <span
                onClick={() => {
                    refine(currentRefinement - 1)
                }}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width={5} height={8} viewBox="0 0 5 8">
                <path d="M461 1156L464.357 1152.072 461 1148 462.57 1148 466 1152.007 462.57 1156z" transform="rotate(180 233 578)" />
            </svg>
            </span>
        }
        {pages.map((it)=>{
           return <span
               className={classNames({
                   'active': currentRefinement == it
               })}
               key={it}
               onClick={()=>{
                   refine(it)
               }}
           >
               {it}
           </span>
        })}
        {(currentRefinement !== nbPages)
            && <span
                onClick={() => {
                    refine(currentRefinement + 1)
                }}
            >
            <svg xmlns="http://www.w3.org/2000/svg" width={5} height={8} viewBox="0 0 5 8">
                <path d="M587 1156L590.357 1152.072 587 1148 588.57 1148 592 1152.007 588.57 1156z" transform="translate(-587 -1148)" />
            </svg>
            </span>
        }
    </div>
}

const PlaceSearchPagination = connectPagination(Pagination)

export {PlaceSearchPagination}


