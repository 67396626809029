class PageHeadUtils {

    init() {
        const loadAppleIcons = '<link rel="apple-touch-icon" sizes="57x57" href="/public/img/favicon/apple-icon-57x57.png">' +
            '<link rel="apple-touch-icon" sizes="60x60" href="/public/img/favicon/apple-icon-60x60.png">' +
            '<link rel="apple-touch-icon" sizes="72x72" href="/public/img/favicon/apple-icon-72x72.png">' +
            '<link rel="apple-touch-icon" sizes="76x76" href="/public/img/favicon/apple-icon-76x76.png">' +
            '<link rel="apple-touch-icon" sizes="114x114" href="/public/img/favicon/apple-icon-114x114.png">' +
            '<link rel="apple-touch-icon" sizes="120x120" href="/public/img/favicon/apple-icon-120x120.png">' +
            '<link rel="apple-touch-icon" sizes="144x144" href="/public/img/favicon/apple-icon-144x144.png">' +
            '<link rel="apple-touch-icon" sizes="152x152" href="/public/img/favicon/apple-icon-152x152.png">' +
            '<link rel="apple-touch-icon" sizes="180x180" href="/public/img/favicon/apple-icon-180x180.png">';

        const loadFavicons = '<link rel="icon" type="image/png" sizes="32x32" href="/public/img/favicon/favicon-32x32.png">' +
            '<link rel="icon" type="image/png" sizes="96x96" href="/public/img/favicon/favicon-96x96.png">' +
            '<link rel="icon" type="image/png" sizes="16x16" href="/public/img/favicon/favicon-16x16.png">' +
            '<link rel="shortcut icon" href="/public/img/favicon/favicon.ico">';

        const loadAndroidIcons = '<link rel="icon" type="image/png" sizes="36x36" href=href="">'+
            '<link rel="icon" type="image/png" sizes="48x48" href="/public/img/favicon/android-icon-48x48.png">'+
            '<link rel="icon" type="image/png" sizes="72x72" href="/public/img/favicon/android-icon-72x72.png">'+
            '<link rel="icon" type="image/png" sizes="96x96" href="/public/img/favicon/android-icon-96x96.png">'+
            '<link rel="icon" type="image/png" sizes="144x144" href="/public/img/favicon/android-icon-144x144.png">'+
            '<link rel="icon" type="image/png" sizes="192x192" href="/public/img/favicon/android-icon-192x192.png">';

        document.head.innerHTML = document.head.innerHTML + loadAppleIcons + loadAndroidIcons + loadFavicons;
    }

}

export const PageHeadUtilsInstance = new PageHeadUtils()