import React from "react";
import {ModelCollection} from "../../../../libs/frontmodel/src";
import {Category} from "../../../../model/Category";
import {Place} from "../../../../model/Place";
import {PlaceMiniMap} from "../../../place/PlaceMiniMap"

const DetailDescription: React.FC<{
    place: Place,
}> = ({place}
) => {

    const categories = place.categories

    return <div className={"grid-desc"}>
        <h3>Herzlich Willkommen im {place.name}!</h3>
        {place.placeDescription
            && <div className={'subsection-desc'}>
                <p>{place.placeDescription}</p>

            </div>
        }
        {categories
            && <div className={'subsection-desc'}>
                <h3>Unsere Küchenkonzepte</h3>
                {categories.array.map((item: any) => (
                    <p key={item.name}>{item.name}</p>
                ))}
            </div>
        }
        {place.additionalInformation
            && <div className={'subsection-desc'}>
                <h3>Sonstiges</h3>
                <p>{place.additionalInformation}</p>
            </div>
        }

    </div>
}
export {DetailDescription};