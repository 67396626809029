import React from "react"

const PlaceMarker: React.FC<{
    lat: number,
    lng: number,
}> = ({lat, lng}) => {

    return <span className={'heart-icon-svg'}>
            <img src={'/public/img/svg/logo-maps.svg'} alt={"Foodlover"}/>
    </span>

}

export {PlaceMarker}