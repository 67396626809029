import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"

export class MobileSearchFilterState extends SubscriptionState {

    static instance = new MobileSearchFilterState()

    isActive = false

    constructor(){
        super()
        this.addResize()
    }

    toggleIsActive = () => {
        this.isActive = !this.isActive
        this.triggerUpdate()
        // @ts-ignore
        document.body.classList.toggle("lock-scroll-window");
        window.addEventListener('resize',this.addResize);
    }
    addResize = () => {
        // TODO: on mobile - Refactor with Current ViewPortState!
        console.log(window.innerWidth + ' Change Me Soon!!!!!!!');
        if( window.innerWidth > 767 && this.isActive){
            this.isActive = false;
            document.body.classList.remove("lock-scroll-window");
            this.triggerUpdate()
        }
    }
}