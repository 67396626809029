import React, {useState} from "react"
import {IFormableProps} from "../libs/formhandling/IFormableProps"
import classNames = require("classnames");
import {ClickOutside} from "../libs/ClickOutside";

export interface ISelectPair {
    value: any,
    humanReadableName?: any
    disabled?: boolean
}


const MultipleSelect: React.FC<
    IFormableProps & {
    selectPairs: Array<ISelectPair>,
    validate?: boolean,
    onSelected?: (value: any)=>any
    placeHolder: string
    labelString?: string
    valueAdder: (value: any) => any
    valueRemover: (value: any) => any
    isSelected: (value: any) => boolean
    errors: string[] | undefined
}
    > = (props) => {

    const formState = props.formState.use()
    const error = props.errors?.[0]
    const isValid = !error ? 'is-valid' : 'is-invalid'
    const labelString = props.labelString!
    const selectPairs = props.selectPairs
    const [isActive, setIsActive] = useState(false)


    const selected = selectPairs.filter((it)=>{
        return props.isSelected(it.value)
    })

    return  <ClickOutside on={()=>{setIsActive(false)}}>
    <div className={'select-box-container'} onClick={()=>{setIsActive(!isActive)}}>
        {labelString
            && <p className={'label-above'}>{labelString}</p>
        }
        <div className={'multiselected-option'}>
            {selected.map((it)=>{
                return <div className={"single-option"}
                    onClick={()=>{
                        props.valueRemover(it.value)
                        formState.triggerUpdate()
                    }}
                    key={it.humanReadableName}
                >
                    {it.humanReadableName}
                    <span className={'close-icon'}></span>
                </div>
            })}
        </div>
        <div className={`plain-input ${isValid} select-box ${isActive ? 'select-index' : ''}`}>
            <div className={`current-selected-option ${isActive ? 'dropdown-open' : ''}`}

            >
                {selected.length
                    ? selected[selected.length - 1]?.humanReadableName
                    : props.placeHolder
                }
                <span className={"select-box-icon"}></span>
            </div>

                <div className={
                    classNames(
                        `select-box-options`,
                        {
                            'is-active': isActive
                        }
                    )
                }>
                    {selectPairs.map((pair) => {
                        return <span
                            className={classNames({
                                "is-selected": props.isSelected(pair.value)
                            })}
                            key={`${pair.humanReadableName}`}
                            onClick={()=>{
                                if (props.isSelected(pair.value)) {
                                    props.valueRemover(pair.value)
                                } else {
                                    props.valueAdder(pair.value)
                                }
                                setIsActive(false)
                                formState.triggerUpdate()
                            }}
                        >
                            {pair.humanReadableName}
                        </span>
                    })}
                </div>

        </div>
        {error &&
        <div className={'errors'}>
            {error}
        </div>
        }
    </div>
    </ClickOutside>
}

export {MultipleSelect}