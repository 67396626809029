import React from "react";
import {Link} from "react-router-dom";

const DefaultButton = (props: {
    btnCls: string,
    urlDirection: string,
    buttonText: string,
    showIconRight?: boolean
}) => {
    return <>

        <Link className={props.btnCls} to={props.urlDirection}><span>{props.buttonText}</span>
            {props?.showIconRight &&
                <svg className={"arrow-right"} xmlns="http://www.w3.org/2000/svg" width={7} height={10} viewBox="0 0 7 10">
                    <path d="M618 402L622.7 397.09 618 392 620.198 392 625 397.008 620.198 402z" transform="translate(-618 -392)" />
                </svg>
            }
        </Link>
    </>
}
export {DefaultButton};