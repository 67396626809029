import React from "react";
import {Link} from "react-router-dom";

const OverlayMessage = (props:{message:string,overlayUrl?:string}) => {
    return <>
        <div className="overlay-header">
            {props.overlayUrl &&
                <Link to={props.overlayUrl}>
                    {props.message}
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16">
                        <path fill="#FFF" d="M363 22L371.058 14.144 363 6 366.768 6 375 14.013 366.768 22z" transform="translate(-363 -6)"/>
                    </svg>
                </Link>
            }
            {!props.overlayUrl &&
                <span>
                    {props.message}
                </span>
            }
        </div>
    </>
}
export {OverlayMessage}