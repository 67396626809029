import {Place} from "./Place";
import {BaseModel, HasOne, Property} from "../libs/frontmodel/src";

export const defaultPlaceholder = "/public/img/placeholder.png"

export class Media extends BaseModel{

    @Property
    id?: number

    @Property
    originalFileName?: string

    @Property
    fileName?: string

    @Property
    path?: string

    @Property
    ownerType?: string

    @Property
    ownerId?: string

    @Property
    fullPath?: string

    @Property
    readableName?: string

    @Property
    extension?: string

    @Property
    placeId?: number

    @Property
    size?: number

    @Property
    contentType?: string

    @Property
    file?: File

    @HasOne("Place")
    place?: Place

    getSrc: ()=>string|undefined = () => {
        if (!this.path) {
            return undefined
        }
        let splitted = this.path.split('/')
        let path = splitted[splitted.length - 1]
        return `https://localfoodlovers.s3.eu-central-1.amazonaws.com/${path}`
    }

    getImageLink = (media:string) => {
        if(media){
            return `https://localfoodlovers.s3.eu-central-1.amazonaws.com/${media}`
        }
    }
}