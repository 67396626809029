import React, {useRef} from "react"
import {RouterNavigationUtils} from "../../utils/routing/RouterNavigationUtils"

const SearchRedirectingSearchBar: React.FC = () => {

    const inputRef = useRef<HTMLInputElement | null>(null)

    const redirectToSearch = () => {
        let value = inputRef.current?.value!!
        RouterNavigationUtils.navigateToSearch(value)
    }

    const handleEnter = (event: any) => {
        if(event.keyCode == 13){
            redirectToSearch()
        }
    }

    return <div className={'search'}>
        <input type="search" ref={inputRef} onKeyDown={handleEnter} placeholder={'PLZ, Ort oder Restaurant'}/>
        <button onClick={redirectToSearch}>
            Suchen
        </button>
    </div>
}

export {SearchRedirectingSearchBar}