import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"
import {getDay, getHours} from 'date-fns'

export class PlaceSearchState extends SubscriptionState {

    static instance = new PlaceSearchState()

    searchState: any = {}

    initialQuery = ""

    onSearchStateChange = (newState: any) => {
        const previousState = this.searchState
        if (this.queryIsDifferent(previousState, newState) && !this.boundingBoxIsDifferent(previousState, newState)) {
            delete newState.boundingBox
        }
        this.searchState = newState
        this.triggerUpdate()
    }

    eveningFacet = false
    todayFacet = false
    nowFacet = false

    setEveningFacet = (value: boolean) => {
        this.eveningFacet = value
        this.triggerUpdate()
    }

    setOpenTodayFacet = (value: boolean) => {
        this.todayFacet = value
        this.triggerUpdate()
    }

    setNowFacet = (value: boolean) => {
        this.nowFacet = value
        this.triggerUpdate()
    }

    getFilters = () => {
        const now = Date.now()
        const dayName = dayToNameMap[getDay(now)]
        const hourNow = getHours(now)
        const baseFilter = `${dayName}OpenTime.day:${dayToNameMap[getDay(Date.now()) as any]}`
        if (this.todayFacet) {
            return `${baseFilter}`
        }
    }

    cleanup = () => {
        this.initialQuery = ""
        this.searchState = {}
        this.eveningFacet = false
        this.todayFacet = false
        this.nowFacet = false
    }

    resetTimeFacets = () => {
        this.todayFacet = false
        this.eveningFacet = false
        this.nowFacet = false
    }

    boundingBoxIsDifferent = (previousState: any, newState: any) => {
        let previousBoundingBox = previousState?.boundingBox
        let newStateBoundingBox = newState?.boundingBox
        if (previousBoundingBox?.northEast?.lat !== newStateBoundingBox?.northEast?.lat) {
            return true
        }
        if (previousBoundingBox?.southEast?.lng !== newStateBoundingBox?.southEast?.lng) {
            return true
        }
        return false
    }

    queryIsDifferent = (previousState: any, newState: any) => {
        let previousQuery = previousState?.query
        let newQuery = newState?.query
        return previousQuery !== newQuery
    }

}

const dayToNameMap: any = {
    1: "mo",
    2: "tue",
    3: "wed",
    4: "thur",
    5: "fr",
    6: "sat",
    7: "sun"
}