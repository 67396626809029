import React from "react";
import {Place} from "../../../../model/Place";
import {ModelCollection} from "../../../../libs/frontmodel/src";
import {PaymentType} from "../../../../model/PaymentType";
import {OrderOption} from "../../../../model/OrderOption";

const DetailService:React.FC<{
    place: Place,
}> = ({place}) => {

    const orderOptions = place.orderOptions
    const paymentTypes = place.paymentTypes

    return <div className={'grid-service'}>
        <div className={'list-wrapper'}>
            <h3>Unser Bestellservice</h3>
            <ul className={'ul-list'}>
                {orderOptions.array.map((it)=>(
                    <li key={it.id}>{it.readableName}</li>
                ))}
            </ul>
        </div>
        <div className={'list-wrapper'}>
            <h3>Unsere Bezahlarten</h3>
            <ul className={'ul-list'}>
                {paymentTypes.array.map((paymentTypes)=>(
                    <li key={paymentTypes.id}>{paymentTypes.name}</li>
                ))}
            </ul>
        </div>
    </div>

}
export {DetailService}