export class LocalStorageRepo {

    static PLACE_SEARCH_KEY = 'placeSearchIndex'

    static putPlaceSearchState = (data: any) => {
        let toStore = ""
        if (data) {
            toStore = JSON.stringify(data)
        }
        window.localStorage.setItem(LocalStorageRepo.PLACE_SEARCH_KEY, toStore)
    }

    static getPlaceSearchState = () => {
        let storedValue = window.localStorage.getItem(LocalStorageRepo.PLACE_SEARCH_KEY)
        if (storedValue) {
            try {
                storedValue = JSON.parse(storedValue)
            } catch (e) {
                LocalStorageRepo.putPlaceSearchState(null)
                storedValue = null
            }
        }
        return storedValue ?? null;
    }

}