import React from "react";
import {ModelCollection} from "../../../../libs/frontmodel/src";
import {defaultPlaceholder, Media} from "../../../../model/Media"
import {Place} from "../../../../model/Place";

const LocaleImage:React.FC<{
    place: Place
}> = ({place}) =>{

    return <>
        <div className={'local-logo'}>
            <img src={place.logoMedia?.getSrc() ?? defaultPlaceholder} alt={place.name + ' Logo'}/>
        </div>
        <div className={'local-image'}>
            {place.imageMedia
                && <img src={place.imageMedia?.getSrc()} alt={place.name + ' Restaurantbild'}/>
            }
        </div>
    </>

}
export {LocaleImage};