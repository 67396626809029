import React from "react";
import {ModelCollection} from "../../../../libs/frontmodel/src";
import {OpenTime} from "../../../../model/OpenTime";
import {Place} from "../../../../model/Place";

const dayWeights: {[id: string]: number} = {
    "MO": 1, "TUE": 2, "WED": 3,
    "THUR": 4, "FR": 5, "SAT": 6, "SUN": 7
}

const sortOpenTimes = (openTimes: ModelCollection<OpenTime>) => {
    openTimes.array = openTimes.array.sort((a,b)=>{
        let aWeight = dayWeights[a.day!] ?? 0
        let bWeight = dayWeights[b.day!] ?? 0
        return aWeight < bWeight ? -1 : 1
    })
}

const DetailCalendar:React.FC<{
    calendar:ModelCollection<OpenTime>,
    place:Place
}> = ({place}) =>{

    sortOpenTimes(place.openTimes)
    const openTimes = place.openTimes

    return <div className={'grid-calendar'}>
        <p className={'calendar-title'}>Öffnungszeiten</p>
        {openTimes.array.map((it)=>(
            <div className={'calendar-row'} key={it.day}>
                <span className={'day-name'}>{it.getHumanReadableName()}</span>
                <span className={'time-start'}>{it.getFormattedFrom()}</span>
                <span className={'dash'}>-</span>
                <span className={'time-end'}>{it.getFormattedTo()}</span>
                {(it.getFormattedExtraFrom()) &&
                    <div className={"calendar-row"}>
                        <span className={'day-name'}></span>
                        <span className={'time-start'}>{it.getFormattedExtraFrom()}</span>
                        <span className={'dash'}>-</span>
                        <span className={'time-end'}>{it.getFormattedExtraTo()}</span>
                    </div>
                }
            </div>
        ))}
    </div>
}
export {DetailCalendar};