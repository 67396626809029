import * as React from "react"

const GeneralErrorsContainer: React.FC<{errors?: string[]}> = ({errors}) => {

    if (!errors || errors?.length < 1) {
        return null
    }

    return  <div className={`error-container`}>
        {errors.map((it) => {
            return <p key={it}>{it}</p>
        })}
    </div>

}

export {GeneralErrorsContainer}