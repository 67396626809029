import {FormState} from "../libs/formhandling/FormState";
import {Place} from "../model/Place";
import {StateModel} from "../libs/statemanagement/StateModel"
import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"

export class PlaceShowState extends SubscriptionState {

    place?: Place

    isLoading: Boolean = false

    setIsLoading = (value: Boolean) => {
        this.isLoading = value
        this.triggerUpdate()
    }

    load = async (placeId: string) => {
        const response = await Place.show({wilds: {placeId: placeId}, isLoadingToggle: this.setIsLoading})
        this.place = response
        this.triggerUpdate()
    }

}