import {
    ApiEndpoint,
    BaseModel,
    HasMany,
    HasOne,
    ModelCollection,
    Property,
    RequestOptions
} from "../libs/frontmodel/src";
import {Media} from "./Media";
import {OpenTime} from "./OpenTime";
import {PaymentType} from "./PaymentType";
import {OrderOption} from "./OrderOption";
import {Address} from "./Address";
import {Category} from "./Category";
import {getDay} from 'date-fns'

export class Place extends BaseModel {

    @Property
    id?: number

    @Property
    name?: string

    @Property
    placeType?: string

    @Property
    tosAccepted?: boolean

    @Property
    placeDescription?: string

    @Property
    additionalInformation?: string

    @Property
    kitchenConcept?: string

    @Property
    serializedAssociated?: string

    @Property
    createdAt?: string

    @Property
    updatedAt?: string

    @Property
    linkSlug?: string

    @HasMany("Media")
    medias!: ModelCollection<Media>

    @HasOne("Media")
    menuMedia?: Media

    @HasOne("Media")
    imageMedia?: Media

    @HasOne("Media")
    logoMedia?: Media

    @HasMany("OpenTime")
    openTimes!: ModelCollection<OpenTime>

    @HasMany("PaymentType")
    paymentTypes!: ModelCollection<PaymentType>

    @HasMany("OrderOption")
    orderOptions!: ModelCollection<OrderOption>

    @HasOne("Address")
    address?: Address

    @HasMany("Category")
    categories!: ModelCollection<Category>

    @ApiEndpoint("POST", {url: `/api/places`})
    create!: (options: RequestOptions) => Promise<Place>

    @ApiEndpoint("POST", {url: "/api/places/update/:placeId"})
    update!: (options: RequestOptions) => Promise<Place>

    @ApiEndpoint("GET", {url: `/api/places/:placeId`})
    static show: (options: RequestOptions) => Promise<Place>

    @ApiEndpoint("GET", {url: `/api/places/edit/:placeId`})
    static forEdit: (options: RequestOptions & {wilds: {placeId: any}}) => Promise<Place>

    static afterForEditRequest(options: RequestOptions) {
        return this.afterShowRequest(options)
    }

    getOrderOptionsForSearchWording = () => {
        let result = []
        let pickup = this.orderOptions.array.find((it)=>{return it.type === "PICKUP"})
        if (pickup) {
            result.push("Abholung")
        }
        let delivery = this.orderOptions.array.find((it)=>{return it.type === "DELIVERY"})
        if (delivery) {
            result.push("Lieferservice")
        }
        return result.join(" / ")
    }

    getTruncatedPlaceDescription = () => {
        const truncated = this.placeDescription?.substring(0, 100)
        if (truncated) {
            return `${truncated}...`
        }
        return undefined
    }

    isOpenNow = () => {
        const dayNumber = getDay(Date.now())
        const openTimeForToday  = this.openTimes.array.find((it)=>{return it.getDayNumber() === dayNumber})
        return openTimeForToday?.isOpenNow()
    }

}