import * as React from 'react'
import ReactDom from 'react-dom'
import { ModelRegistrator } from './model/ModelRegistrator';
import {RootComponent} from "./components/RootComponent";
import {LocalStorageManager} from "./utils/LocalStorageManager";
import {CurrentViewportState} from "./state/CurrentViewportState"
import {RouterNavigationUtils} from "./utils/routing/RouterNavigationUtils"
import {Router} from "react-router-dom"
import {PageHeadUtilsInstance} from "./utils/PageHeadUtils";

export class App {

    static init() {
        CurrentViewportState.init()
        ModelRegistrator.run()
        LocalStorageManager.init()
        PageHeadUtilsInstance.init()
        document.addEventListener('DOMContentLoaded', ()=>{
            this.mount()
        })
    }

    static mount() {
        const mountElement = document.querySelector('#app')
        ReactDom.render(
            <Router history={RouterNavigationUtils.history}>
                <RootComponent/>
            </Router>,
            mountElement
        )
    }

}