import React from "react"
import { connectCurrentRefinements } from "react-instantsearch-dom"
import {PlaceSearchState} from "../../state/PlaceSearchState"

const ClearRefinements: React.FC<{
    items: any,
    refine: (items: any)=>any
}> = ({items, refine}) => {

    return <div
        className={'filter-title'}
        onClick={()=>{
            PlaceSearchState.instance.resetTimeFacets()
            refine(items)
        }}
    >
        <span>Filter</span><span>zurücksetzen</span>
    </div>

}


const PlaceClearRefinements = connectCurrentRefinements(ClearRefinements)

export {PlaceClearRefinements}