import {ApiEndpoint, BaseModel, ModelCollection, Property, RequestOptions} from "../libs/frontmodel/src"

export class OrderOption extends BaseModel {

    @Property
    id?: number

    @Property
    type?: string

    @Property
    readableName?: string

    @Property
    required?: boolean

    @ApiEndpoint("GET", {url: `/api/orderOptions`})
    static index: (options?: RequestOptions) => Promise<ModelCollection<OrderOption>>

}