import React from "react";
import {BlogArticle} from "./BlogArticle";

const Blog = () => {
    return <>
        <div className={'section grey'}>
            <div className={'container'}>
                <BlogArticle image={'/public/img/blog-images/Zwiesel.png'}
                             overline={'Zwiesel Glas als Mitgründer der Plattform'}
                             headline={'Getreu dem Markenkern „for those who care“ möchte Zwiesel Glas der Gastronomieszene in ganz Deutschland und Österreich helfen'}
                             url={'http://www.zwiesel-glas.de'}/>

                <BlogArticle image={"/public/img/blog-images/Av_Logo_Quer.png"}
                             overline={"Vom Konzept zum Livegang in 7 Tagen"}
                             headline={"Wie aus einer Idee mit einem super Team in nur 7 Tagen der Gastro-Guide www.local-foodlovers.de entstand."}
                             url={"https://www.active-value.de/blog/vom-konzept-bis-zum-going-live-in-7-tagen"}/>

                <BlogArticle image={"/public/img/blog-images/LFL_Logo_Quer.png"}
                             headline={"Trotz der nach und nach eintretenden Lockerungen für die Gastronomie unterstützt die Plattform auch weiterhin – jetzt sogar in Österreich"}
                             overline={"Local Foodlovers geht weiter"}
                             url={"https://www.instagram.com/local_foodlovers/"}/>

                <BlogArticle image={"/public/img/blog-images/Tim_Raue_Quer.png"}
                             overline={"Tim Raue freut sich über Local Foodlovers"}
                             headline={"Auch der Starkoch ruft zum Zusammenhalt in dieser schwierigen Zeit auf und bietet seine Abhol- und Lieferservices auf der Plattform an"}
                             url={"https://tim-raue.com/"}/>
            </div>
        </div>
    </>
}
export {Blog}