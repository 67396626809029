import React from "react"
import {AdditionalSearchState} from "../../state/AdditionalSearchState"

const PlaceSearchMapNoResults: React.FC = () => {

    const searchState = AdditionalSearchState.instance.use()

    return <>
        {searchState.noSearchResults
            && <div className={'mapoverlay no-results'}>Leider keine Ergebnisse gefunden.</div>
        }
    </>
}

export {PlaceSearchMapNoResults}