import React from "react";

const SuccessPage = () => {
    return <>
        <div className={"main-form success"}>
            <div className={'container'}>

                    <div className={"form-headline-row"}>
                        <p className={'form-title'}><b>Vielen Dank für Ihre Registrierung!</b></p>
                        <p className={'form-introduction'}>In Kürze finden Sie Ihr Restaurant auf unserer Plattform. Bei Fragen stehen wir Ihnen selbstverständlich telefonisch und per Mail zur Verfügung.</p>
                    </div>

            </div>
        </div>
    </>
}
export {SuccessPage}