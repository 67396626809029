import React from "react";
import {IconText} from "./IconText";
import {Link} from "react-router-dom";

const IntroductionSection = () =>{
    return <div className={'section white'}>
        <div className={"container wide"}>
            <div className={'inline-row'}>
                <IconText
                    title={'Für Foodlovers'}
                    icon={'/public/img/icons/Herz_grau.svg'}
                    text={'Sei es der lang ersehnte Restaurantbesuch mit neuen Hygiene- und Abstandsregeln' +
                    ' oder doch noch der speziell angebotene Bestell- und Lieferservice' +
                    ' in eurem Lieblingslokal – über Local Foodlovers' +
                    ' findet ihr das Angebot in eurer Nähe. Probiert es aus, unterstützt' +
                    ' eure lokalen Restaurants, Winzer und Bars und wenn ihr happy mit allem seid,' +
                    ' nutzt eure Social Media Power und verbreitet es. So stemmen wir gemeinsam diese ' +
                    'Krise und helfen mit einem tollen Essen alle ein bisschen mit.'}/>

                <IconText
                    title={'Für Gastronomen'}
                    icon={'/public/img/icons/Besteck_grau.svg'}
                    text={'Wir wollen alle Restaurants kostenlos unterstützen und sie durch diese Zeit bringen. Alle Restaurants sind eingeladen, teilzunehmen.\n' +
                    'Ladet direkt Fotos, Bilder, Menüs und alle relevanten Informationen hoch und wir machen Euch und Euer Angebot auffindbar.'}/>

            </div>
            <div className={'inline-row center'}>
                <Link className={'btn white-filled bordered'} to={'/registrieren'}>
                    Jetzt kostenlos anmelden
                </Link>
            </div>
        </div>
    </div>
}
export {IntroductionSection}