import * as React from 'react'
import {Redirect, Route, Switch, useLocation} from "react-router"
import {FlashNotifications} from "./layouts/layoutelements/overlay/FlashNotifications";
import {XhrRequestMaker} from "../libs/frontmodel/src";
import {RouterNavigationUtils} from "../utils/routing/RouterNavigationUtils";
import {BodyClassesState} from "../state/BodyClassesState";
import classNames from "classnames";
import {WelcomePage} from "../pages/WelcomePage";
import {Footer} from "./layouts/layoutelements/footer/Footer";
import {Cookiebanner} from "./layouts/layoutelements/cookiebanner/Cookie";
import {FormPage} from "../pages/FormPage";
import {HeaderLayout} from "./layouts/layoutelements/header/HeaderLayout";
import {SuccessPage} from "../pages/SuccessPage";
import {CookiesProvider, useCookies} from "react-cookie"
import {SubFooter} from "./layouts/layoutelements/subfooter/Subfooter";
import {AgbPage} from "../pages/agb";
import {StartPage} from "../pages/StartPage";
import {PlaceShow} from "./place/PlaceShow"
import {PlaceSearchIndex} from "./place/PlaceSearchIndex"
import {ErrorPage} from "../pages/ErrorPage";
import {MobileSearchFilterState} from "../state/MobileSearchFilterState"
import {PlaceEdit} from "./place/PlaceEdit"
import {GaHandler} from "../utils/tracking/GaHandler"
import {LoginViaLink} from "./session/LoginViaLink"
import {NavigationHistoryStore} from "../utils/NavigationHistoryStore"

XhrRequestMaker.onFailHandler = (xhr) => {
    if (xhr.status === 404) {
        RouterNavigationUtils.history.replace('/404')
        return
    }
    RouterNavigationUtils.history.replace('/error')
}

export const RootComponent: React.FC = () => {

    const location = useLocation()
    NavigationHistoryStore.pushPath(location.pathname)

    const bodyClassesState = BodyClassesState.instance.use()
    const [cookies, setCookies] = useCookies(['AllowAllCookies','AllowOnlyTecCookies']);
    GaHandler.cookies = cookies
    GaHandler.initialize()
    GaHandler.usePageViews()

    return <main className={`app-body ${classNames(bodyClassesState.value)}`}>
            <FlashNotifications/>
            <HeaderLayout/>
            <div className={'body-content'}>
            <Switch>
                <Route path={'/error'}>
                    <ErrorPage
                        errorMessage={
                            <>
                                <h1>Server error</h1>
                                <div className={"decorative-span"}><span>500 Error</span></div>
                            </>
                        }
                        message={
                            <>
                                <p>Geh' doch zurück zur letzten Seite oder klick' auf das Herz, um zurück auf unsere Startseite zu
                                    gelangen
                                </p>

                            </>
                        }
                    >
                    </ErrorPage>
                </Route>
                <Route exact path={'/404'}>
                    <ErrorPage
                        errorMessage={
                            <>
                                <h1>Sorry, die Seite ist leider aus.</h1>
                                <div className={"decorative-span"}><span>404 Error</span></div>
                            </>
                        }
                        message={
                            <p>Geh' doch zurück zur letzten Seite oder klick' auf das Herz, um zurück auf unsere Startseite zu
                                gelangen
                            </p>
                        }
                    />
                </Route>
                <Route path={`/places/edit/:placeId`}
                       render={(props)=>{
                           const placeId = props.match.params.placeId
                           return <PlaceEdit
                               key={`${placeId}edit`}
                               placeId={placeId}
                           />
                       }}
                />
                <Route path={`/places/:placeId`}
                    render={(props)=>{
                        const placeId = props.match.params.placeId
                        return <PlaceShow
                            key={placeId}
                            placeId={placeId}
                        />
                    }}
                />
                <Route path={`/loginViaLink`}>
                    <LoginViaLink/>
                </Route>
                <Route path={`/places`}>
                    <PlaceSearchIndex/>
                </Route>
                <Route path={'/success'}>
                    <SuccessPage/>
                </Route>
                <Route exact path={'/registrieren'}>
                    <FormPage/>
                </Route>
                <Route path={'/agb'}>
                    <AgbPage/>
                </Route>
                <Route exact path={"/"}>
                    <StartPage/>
                </Route>
                <Route>
                    <Redirect to={`/404`}/>
                </Route>
            </Switch>
            </div>
        <SubFooter/>
        <Footer/>
        <CookiesProvider>
            <Cookiebanner/>
        </CookiesProvider>
    </main>
}