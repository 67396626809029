import * as GA from 'react-ga'
import {useLocation} from "react-router"
import {useEffect} from "react"

export class GaHandler {

    static IDENTIFIER = 'UA-163780225-1'

    static initialized = false

    static cookies: {AllowAllCookies?: any} = {}

    static initialize = () => {
        if (GaHandler.cookies.AllowAllCookies && !GaHandler.initialized) {
            GaHandler.initialized = true
            GA.initialize(GaHandler.IDENTIFIER)
            GA.pageview(window.location.pathname)
        }
    }

    static getGa = () => {
        if (!GaHandler.initialized) {
            return null
        }
        return GA
    }

    static usePageViews = () => {
        let location = useLocation()

        useEffect(()=>{
            GaHandler.getGa()?.pageview(location.pathname)
        }, [location])
    }

}