import React from "react"
import {PlaceCheckboxRefinements} from "./PlaceCheckboxRefinements"
import {OpenTimesFacets} from "./OpenTimesFacets"
import {PlaceHits} from "./PlaceHits"
import {AdditionalSearchState} from "../../state/AdditionalSearchState"
import classNames from "classnames"
import {PlaceClearRefinements} from "./PlaceClearRefinements"
import {MobileSearchFilterState} from "../../state/MobileSearchFilterState"

const PlaceFacets = () => {

    const additionalSearchState = AdditionalSearchState.instance.use()
    const mobileSearchFilterState = MobileSearchFilterState.instance.use()

    return <div
        className={classNames(
            'filter',
            {
                'active': mobileSearchFilterState.isActive
            }
        )}
        style={additionalSearchState.noSearchResults ? {display: "none"} : undefined}
    >
        <PlaceClearRefinements/>
        <span className={'section-subtitle-filter'}>Bestelloptionen</span>
        <PlaceCheckboxRefinements
            attribute="orderOptions.readableName"
            className={"orderservices"}
        />
        <span className={'section-subtitle-filter'}>Küche</span>
        <PlaceCheckboxRefinements
            attribute="categories.name"
            className={"kitchentypes"}
            sortAlphabetically={true}
            limit={100}
        />
    </div>

}

export {PlaceFacets}