import React from "react";
import {SupporterImage} from "./SupporterImage";

const Supporter = () => {
    return <div className={"section dark"}>
        <div className={'container'}>
            <h3 className={'supporter-title'}>Empfohlen von:</h3>
            <div className={'logo-section'}>
                <SupporterImage image={"/public/img/supporter/Salon.png"} url={"http://www.salon-mag.de/"} alt={"Salon-Mag"}/>
                <SupporterImage image={"/public/img/supporter/JRE.png"} url={"https://www.jre.eu/de/deutschland-de"} alt={"JRE"}/>
                <SupporterImage image={"/public/img/supporter/falstaff.png"} url={"http://www.falstaff.de/"} alt={"Falstaff"}/>
                <SupporterImage image={"/public/img/supporter/bundesgesellschaftsstelle.png"} url={"https://www.vdp.de/de"} alt={"VDP"}/>
                <SupporterImage image={"/public/img/supporter/Die_Jungs.png"} url={"https://www.diejungskochenundbacken.de/"} alt={"Die Jungs"}/>
                <SupporterImage image={"/public/img/supporter/Meininger.png"} url={"https://www.meininger.de/de"} alt={"Meininger"}/>
                <SupporterImage image={"/public/img/supporter/asktoni.png"} url={"https://asktoni.de/"} alt={"Asktoni"}/>
                <SupporterImage image={"/public/img/supporter/Schumann.png"} url={"http://www.schumanns.de/de/schumanns-bar.html"} alt={"Schumanns-Bar"}/>
                <SupporterImage image={"/public/img/supporter/navos.png"} url={"https://www.navos.eu/"} alt={"Navos Public Dialogue Consultants"}/>
                <SupporterImage image={"/public/img/supporter/klocke.png"} url={"https://www.klocke-verlag.de/"} alt={"Klocke Verlag"}/>
                <SupporterImage image={"/public/img/supporter/deutsche_weine.png"} url={"https://www.deutscheweine.de/"} alt={"Deutsches Weininstitut"}/>
                <SupporterImage image={"/public/img/supporter/langencup.png"} url={"https://www.lagencup.de/"} alt={"LangenCup"}/>
                <SupporterImage image={"/public/img/supporter/beat.png"} url={"https://shop.food-magazine.de/beat-mag/"} alt={"BEAT"}/>
                <SupporterImage image={"/public/img/supporter/chaine.jpg"} url={"http://www.chaine.de"} alt={"Chaîne des Rôtisseurs"}/>
                <SupporterImage image={"/public/img/supporter/lartdevivre.png"} url={"https://lartdevivre-residenzen.com/"} alt={"L'Art de Vivre"}/>

            </div>
        </div>
    </div>
}
export {Supporter}