import {Place} from "../../model/Place"
import React from "react"
import {Link} from "react-router-dom"
import {defaultPlaceholder} from "../../model/Media"

const PlaceHitShow: React.FC<{ place: Place }> = ({place}) => {

    const identifierForLink = place.linkSlug ?? place.id

    return <div className={'listing-article'}>
        <div className={'article-image-wrapper'}>
            <img src={place.logoMedia?.getSrc() ?? defaultPlaceholder} alt={place.name}/>
        </div>
        <div className={'article-info-wrapper'}>
            <div className={"article-main-text-wrap"}>
                <p className={'article-title'}>{place.name}</p>
                <p className={'article-description'}>{place.getTruncatedPlaceDescription()}</p>
            </div>
            <div className={'article-row-info'}>
                <p className={'article-order-options'}>
                    {place.getOrderOptionsForSearchWording()}
                </p>
                <Link to={`/places/${identifierForLink}`} className={'btn filled-black'}>
                    zum Restaurant
                </Link>
            </div>
        </div>
    </div>

}

export {PlaceHitShow}