import {ApiEndpoint, BaseModel, ModelCollection, Property, RequestOptions} from "../libs/frontmodel/src"

export class PaymentType extends BaseModel {

    @Property
    id?: number

    @Property
    systemName?: string

    @Property
    name?: string

    @Property
    logoLink?: string

    @ApiEndpoint("GET", {url: `/api/paymentType`})
    static index: (options?: RequestOptions) => Promise<ModelCollection<PaymentType>>

}