import React from "react";
import {Link} from "react-router-dom";

const ErrorPage: React.FC<{
    message: any,
    errorMessage: any
}> = ({errorMessage, message}) => {
    return <>
        <div className={'error-banner'}>
            <Link to={'/'}><img className={'logo'} src={'/public/img/svg/logo.svg'}/></Link>
            {errorMessage}
            {message}
            <Link to={'/registrieren'} className={'btn'}>Jetzt kostenlos Restaurant, Bar oder Weingut anmelden
                <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" width={7} height={10}
                     viewBox="0 0 7 10">
                    <path d="M618 402L622.7 397.09 618 392 620.198 392 625 397.008 620.198 402z"
                          transform="translate(-618 -392)"/>
                </svg>
            </Link>
            <p className={"subline"}>#SupportYourLocalRestaurants</p>
        </div>

    </>
}
export {ErrorPage};