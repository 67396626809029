import React, {useMemo, useState} from "react";
import {PlainInput} from "../formelements/PlainInput";
import {MediaUploadInput} from "../formelements/MediaUploadInput";
import {TextAreaInput} from "../formelements/TextAreaInput";
import {getCategoriesForSelect, NewPLaceFormState} from "../formstates/NewPLaceFormState";
import {asKeyOf} from "../libs/typeutils/asKeyOf";
import {Place} from "../model/Place";
import {MiniSingleSelectInput} from "../formelements/MiniSingleSelectInput";
import {OpenTime} from "../model/OpenTime";
import {MultiCheckboxGroupInput} from "../formelements/MultiCheckboxGroupInput";
import {Address} from "../model/Address";
import {GeneralErrorsContainer} from "../formelements/GeneralErrorsContainer";
import {MultipleSelect} from "../formelements/MultipleSelect";
import {ValueBasedCheckbox} from "../formelements/ValueBasedCheckbox";
import Loader from "react-loader-spinner";
import {TimeInput} from "../formelements/TimeInput"
import {OpenTimeEditBlock} from "../opentime/OpenTimesEditBlock"

const FormPage: React.FC = () => {

    const formState = useMemo(() => {
        return NewPLaceFormState.init()
    }, []).use();
    const place = formState.model
    const openTimes = place.openTimes
    const address = place.address!

    return <div className={"main-form"}>
        <div className={'container'}>
            <div className={"form-headline-row"}>
                <p className={'form-title'}>Jetzt Formular ausfüllen und kostenlos anmelden</p>
                <p className={'form-introduction'}>Trage dich jetzt kostenfrei bei Local Foodlovers ein und biete deinen
                    Liefer- oder Abholservice auf der Plattform an. www.local-foodlovers.com ist 100% kostenfrei und
                    möchte dazu beitragen, die Gastroszene in Deutschland und Österreich zu erhalten. Bitte beachte,
                    dass wir dir nur die Plattform bieten, über die du Bestellungen für dein Restaurant erhalten kannst.
                    Die Abwicklung der Bezahlung und deinen Liefer- oder Abholservice sowie weitere Angebote liegt bei dir.
                </p>
            </div>
            <div className={'form-card'}>
                <p className={'card-title'}>Basis-Infos über dein Restaurant</p>
                <PlainInput type={'text'}
                            property={asKeyOf<Place>('name')}
                            formState={formState}
                            formModel={place}
                            validateFun={() => {
                                formState.validatePlaceName(place)
                            }}
                            isRequired={true}
                            labelText={'Restaurantname*'}
                            placeholderText={"Wie heißt dein Restaurant?"}
                />
                <MediaUploadInput formState={formState}
                                  formModel={place.logoMedia!}
                                  property={'file'}
                                  labelText={'Restaurantlogo'}
                                  placeholder={'JPG-,GIF- oder PNG-Datei'}
                                  validate={() => {
                                      formState.validatePlaceLogoMedia(place.logoMedia!)
                                  }}
                />
                <MediaUploadInput formState={formState}
                                  formModel={place.imageMedia!}
                                  property={'file'}
                                  labelText={'Restaurantbild'}
                                  placeholder={'Ein Bild Ihres Restaurants'}
                                  validate={() => {
                                      formState.validatePlaceImageMedia(place.imageMedia!)
                                  }}
                />
                <TextAreaInput
                    formState={formState}
                    formModel={place}
                    property={asKeyOf<Place>('placeDescription')}
                    labelText={'Restaurantbeschreibung'}
                    placeholderText={'Beschreibe dein Restaurant mit ein paar Worten.'}
                />

                <div className={"calendar-select"}>
                    <p>Öffnungszeiten*</p>
                    <OpenTimeEditBlock
                        formState={formState}
                        openTimes={openTimes}
                    />
                </div>

                <div className={'single-select'}>
                    <MultipleSelect
                        formState={formState}
                        formModel={place}
                        labelString={"Küchenkonzept*"}
                        property={asKeyOf<Place>("categories")}
                        selectPairs={getCategoriesForSelect}
                        placeHolder={"Welche Küche bietest du an? (siehe Auswahlmenü)"}
                        valueAdder={(value) => {
                            formState.addCategory(place, value)
                        }}
                        valueRemover={(value) => {
                            formState.removeCategory(place, value)
                        }}
                        isSelected={(value) => {
                            return formState.isCategorySelected(place, value)
                        }}
                        errors={place.getErrorsFor(asKeyOf<Place>('categories')) ?? undefined}
                    />
                </div>

            </div>


            <div className={'form-card'}>
                <p className={'card-title'}>Wichtige Service-Infos</p>

                <div className={"checkbox-row"}>

                    <div className={"checkbox-row"}>
                        <div className={'row-wrapper orderoptions'}>
                            <MultiCheckboxGroupInput
                                formState={formState}
                                formModel={place}
                                property={asKeyOf<Place>("orderOptions")}
                                placeHolder={"Bestelloptionen*"}
                                selectPairs={formState.orderOptionsForSelect}
                                elementAdder={formState.addOrderOption as any}
                                elementRemover={formState.removeOrderOption as any}
                                isSelected={formState.isOrderOptionSelected as any}
                                isValid={formState.orderOptionIsValid as any}
                                showErrors={!!place.getErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)}
                            />
                            {place.getErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)
                            && <div className={"error"}>
                                {place.getErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)?.map((it) => {
                                    return <p>{it}</p>
                                })}
                            </div>
                            }
                        </div>

                    </div>

                    <MediaUploadInput formState={formState}
                                      formModel={place.menuMedia!}
                                      property={'file'}
                                      labelText={'Speisekarten Downloadmöglichkeit (PDF)'}
                                      placeholder={'Hier kannst Du deine Speisekarte hochladen'}
                                      validate={() => {
                                          formState.validatePlaceMenuMedia(place.menuMedia!)
                                      }}
                    />

                    <div className={'checkbox-row'}>
                        <div className={'row-wrapper'}>
                            <MultiCheckboxGroupInput
                                formState={formState}
                                formModel={place}
                                property={asKeyOf<Place>("paymentTypes")}
                                placeHolder={"Zahlarten*"}
                                selectPairs={formState.paymentTypesForSelect}
                                elementAdder={formState.addPaymentType as any}
                                elementRemover={formState.removePaymentType as any}
                                isSelected={formState.isPaymentTypeSelected}
                                showErrors={false}
                                isValid={() => true}
                            />
                            {place.getErrorsFor(NewPLaceFormState.PAYMENT_TYPE_SELECTION)
                            && <div className={"error"}>
                                {place.getErrorsFor(NewPLaceFormState.PAYMENT_TYPE_SELECTION)?.map((it) => {
                                    return <p>{it}</p>
                                })}
                            </div>
                            }
                        </div>
                    </div>
                    <TextAreaInput
                        formState={formState}
                        formModel={place}
                        property={asKeyOf<Place>('additionalInformation')}
                        isRequired={false}
                        labelText={'Sonstige Infos'}
                        placeholderText={"Bitte trage hier weitere, gewünschte Infos ein"}
                    />

                </div>
            </div>
            <div className={'form-card contact-data'}>
                <p className={'card-title'}>Ihre Kontaktdaten</p>
                <div className={'row inline-xs'}>
                    <div className={'col-7 col-md-9'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('street')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressStreet(address)
                                    }}
                                    labelText={'Straße*'}
                                    placeholderText={"Hier bitte den Straßenname angeben"}
                        />
                    </div>
                    <div className={'col-5 col-md-3'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>("number")}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Hausnummer*'}
                                    placeholderText={"… und die Nr."}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressNumber(address)
                                    }}
                        />
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-md-4'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>("zipCode")}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Postleitzahl*'}
                                    placeholderText={"Bitte die PLZ angeben"}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressZipCode(address)
                                    }}
                        />
                    </div>
                    <div className={'col-12 col-md-8'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('city')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Ort*'}
                                    placeholderText={"Wo befindet sich ihr Restaurant?"}
                                    validateFun={(model: Address, property: string, value: any) => {
                                        formState.validateAddressCity(address)
                                    }}
                        />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('phone')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={true}
                                    labelText={'Telefonnummer*'}
                                    placeholderText={"Telefonnummer"}
                                    validateFun={() => {
                                        formState.validateAddressPhoneNumber(address)
                                    }}
                        />
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('email')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'E-Mail*'}
                                    placeholderText={"Deine E-Mail-Adresse"}
                                    validateFun={() => {
                                        formState.validateAddressEmail(address)
                                    }}
                        />
                    </div>
                </div>


                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('website')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'Website'}
                                    placeholderText={"Wie lautet deine Websiteadresse?"}
                                    validateFun={() => {
                                        formState.validateWebsite(address)
                                    }}
                        />
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('socialMediaAddress1')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'Facebook'}
                                    placeholderText={"Social-Media-Adresse"}
                        />
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <PlainInput type={'text'}
                                    property={asKeyOf<Address>('socialMediaAddress2')}
                                    formState={formState}
                                    formModel={address}
                                    isRequired={false}
                                    labelText={'Instagram'}
                                    placeholderText={"Social-Media-Adresse"}
                        />
                    </div>
                    <div className={'col-12'}>
                        <ValueBasedCheckbox formState={formState}
                                            formModel={place}
                                            property={asKeyOf<Place>('tosAccepted')}
                                            placeholderText={
                                                <>Mit der Registrierung bei www.local-foodlovers.de akzeptiere ich die <a target={'_blank'} href={"/agb"}>AGB</a> und die darin enthaltenen Datenschutzbestimmungen.</>
                                            }
                                            isRequired={true}
                                            valueOnChecked={true}
                                            valueOnUnchecked={false}
                                            validateFun={()=>{formState.validateTosAccepted(place)}}
                        />
                    </div>

                    <div className={'col-12 col-md-6'}>
                        <GeneralErrorsContainer
                            errors={place.getErrorsFor('general')}
                        />
                    </div>
                    <div className={"row last submit-row"}>
                        <span className={'note-necessary'}>*verpflichtende Angaben</span>
                        <div
                            className={'btn filled-white'}
                            onClick={() => {
                                formState.save()
                            }}
                        >
                            <span>Senden</span>
                            {formState.isLoading &&
                                <Loader height={25} width={25} color={'#ccc'} type={'ThreeDots'}/>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


}

export { FormPage }
