import React from "react";
import {Link} from "react-router-dom";
import {CheckCurrentLocation} from "../../../../libs/helper/CurrentLocation";
import {SearchRedirectingSearchBar} from "../../../place/SearchReiderctingSearchBar";

const shouldRender = !['/success', '/registrieren','/','/start'].includes(location.pathname)
const shouldRenderSearch = ['/start','/'].includes(location.pathname)
const SubFooter = () => {
    return <>
        <div className={`subfooter${shouldRenderSearch ? ' search-footer' : ''}`}>
            <div className={'container'}>
                <p className={"hashtag"}>#SupportYourLocalRestaurants</p>
                {shouldRender &&
                    <Link className={"gotoregister"} to={"/registrieren"}>Jetzt kostenlos anmelden</Link>
                }
                {shouldRenderSearch &&
                    <SearchRedirectingSearchBar/>
                }
            </div>
        </div>
    </>
}
export {SubFooter};