import React, {useRef, useState} from "react"
import { connectRefinementList } from "react-instantsearch-dom"
import classNames from "classnames"

export interface IRefinableFacet {
    label: string,
    count: any,
    isRefined: boolean,
    value: any,
}

const CheckboxRefinements: React.FC<{
    attribute: string,
    searchForItems: (value: any)=>any
    items: IRefinableFacet[],
    refine: (value: any) => any
    className?: string
    showMoreAfter?: number
    sortAlphabetically?: boolean
}> = ({items, className, refine, showMoreAfter, sortAlphabetically}) => {

    const [isCollapsed, setIsCollapsed] = useState(true)

    if (sortAlphabetically) {
        items = items.sort((a,b)=>{return a.label as any > b.label as any ? -1 : 1})
    } else {
        items = sortOrderOptions(items)
    }
    if (showMoreAfter && isCollapsed) {
        items = items.slice(0, showMoreAfter)
    }

    return <div className={className}>
        {items.map((it)=>{
            return <div className={'checkbox-row-filter'}
                key={it.label}
                onClick={()=>{
                    refine(it.value)
                }}
            >
                <span className={classNames('checkbox',{'selected': it.isRefined})}>
                </span>
                <span className={'checkbox-label'}>{it.label}</span>
            </div>
        })}
        {(showMoreAfter && isCollapsed)
            && <span className={"show-expand"}
                onClick={() => setIsCollapsed(false)}
            >
               mehr anzeigen
            </span>
        }
        {(showMoreAfter && !isCollapsed)
            && <span className={"show-expand"}
                onClick={()=> setIsCollapsed(true)}
            >
                weniger anzeigen
            </span>
        }
    </div>
}

const sortOrderOptions = (items: IRefinableFacet[]) => {
    return items.sort((a, b)=>{
        const aWeight = weights[a.label.toLowerCase()] ?? 0
        const bWeight = weights[b.label.toLowerCase()] ?? 0
        if (aWeight == 0 && bWeight == 0) {
            return a.label > b.label ? -1 : 1
        }
        if (aWeight === bWeight) {
            return 0
        }
        return aWeight > bWeight ? -1 : 1
    })
}

const weights: {[id:string]: number} = {
    "abholung": 11,
    "lieferservice": 10,
    "Getränkeservice": 9,
    "Menüangebote": 8
}

const PlaceCheckboxRefinements = connectRefinementList(CheckboxRefinements as any) as any

export {PlaceCheckboxRefinements}