import {availablePaymentTypes, getDefaultOpenTimes, getOrderOptionsForSelect, NewPLaceFormState} from "./NewPLaceFormState"
import {Place} from "../model/Place"
import {Media} from "../model/Media"
import {Address} from "../model/Address"
import {RouterNavigationUtils} from "../utils/routing/RouterNavigationUtils"
import {ModelCollection} from "../libs/frontmodel/src"
import {Category} from "../model/Category"
import {ISelectPair} from "../formelements/MultipleSelect"
import {OrderOption} from "../model/OrderOption"
import {IMiniCheckboxGroupSelect} from "../formelements/MultiCheckboxGroupInput"
import {PaymentType} from "../model/PaymentType"
import {Simulate} from "react-dom/test-utils"
import select = Simulate.select

export class PlaceEditFormState extends NewPLaceFormState {

    static initForEdit(placeId: number) {
        let formSate = new this(new Place())
        formSate.loadPlace(placeId)
        formSate.loadCategories()
        formSate.loadPaymentTypes()
        formSate.loadOrderOptions()
        return formSate
    }

    categories = new ModelCollection<Category>()
    categoriesSelectPairs: ISelectPair[] = []

    orderOptions = new ModelCollection<OrderOption>()
    orderOptionsForSelect: IMiniCheckboxGroupSelect[] = []

    paymentTypes = new ModelCollection<PaymentType>()
    paymentTypesForSelect: IMiniCheckboxGroupSelect[] = []

    setCategories = (categories: ModelCollection<Category>) => {
        this.categories = categories
        this.categoriesSelectPairs = categories.array.map((it) => {
            let pair: ISelectPair = {
                humanReadableName: it.name,
                value: it
            }
            return pair
        })
    }

    setOrderOptions = (orderOptions: ModelCollection<OrderOption>) => {
        this.orderOptions = orderOptions
        this.orderOptionsForSelect = orderOptions.map((it)=>{
            let selectPair: IMiniCheckboxGroupSelect = {
                placeHolder: it.readableName!,
                value: it
            }
            return selectPair
        })
    }

    setPaymentTypes = (paymentTypes: ModelCollection<PaymentType>) => {
        this.paymentTypes = paymentTypes
        this.paymentTypesForSelect = paymentTypes.map((it)=>{
            let selectPair: IMiniCheckboxGroupSelect = {
                value: it,
                placeHolder: it.name!,
                image: it.logoLink
            }
            return selectPair
        })
    }

    validate = () => {
        const place = this.model
        this.validatePlaceName(place)
        this.validatePlaceImageMedia(place.imageMedia!)
        this.validatePlaceMenuMedia(place.menuMedia!)
        this.validatePlaceLogoMedia(place.logoMedia!)
        const address = place.address!
        this.validateAddressCity(address)
        this.validateAddressZipCode(address)
        this.validateAddressStreet(address)
        this.validateAddressNumber(address)
        this.validateAddressEmail(address)
        this.validateAddressPhoneNumber(address)
        this.validatePlacePaymentTypesPresence(place)
        this.validateAtLeastOneRequiredOrderOptionSelected(place)
        this.validateWebsite(address)
        this.validateCategories(place)
    }

    loadPlace = async (placeId: number) => {
        let place = await Place.forEdit({wilds: {placeId}})
        place.menuMedia = place.menuMedia ?? new Media()
        place.imageMedia = place.imageMedia ?? new Media()
        place.logoMedia = place.logoMedia ?? new Media()
        place.openTimes = place.openTimes ?? getDefaultOpenTimes()
        place.address = place.address ?? new Address()
        this.model = place
        this.triggerUpdate()
    }

    loadCategories = async () => {
        let categories = await Category.index()
        this.setCategories(categories)
        this.triggerUpdate()
    }

    loadOrderOptions = async () => {
        let orderOptions = await OrderOption.index()
        this.setOrderOptions(orderOptions)
        this.triggerUpdate()
    }

    loadPaymentTypes = async () => {
        let paymentTypes = await PaymentType.index()
        this.setPaymentTypes(paymentTypes)
        this.triggerUpdate()
    }

    isLoaded = () => {
        return !!this.model.id
    }

    addCategoryFromModelValue = (place: Place, toAdd: Category) => {
        let exists = place.categories?.array?.find((it)=>{return it.id === toAdd.id})
        if (exists) {
            return
        }
        place.categories.array.push(toAdd)
        this.validateCategories(place)
    }

    removeCategoryFromModelValue = (place: Place, toRemove: Category) => {
        place.categories.array = place.categories.array.filter((it)=>{return it.id !== toRemove.id})
        this.validateCategories(place)
    }

    isCategorySelectedFromModelValue = (place: Place, value: Category) => {
        return !!place.categories.array.find((it)=>{return it.id === value.id})
    }

    update = async () => {
        const place = this.model
        this.validate()
        if (!place.isValid()) {
            this.triggerUpdate()
            return
        }
        const response = await place.update({
            serializeAsForm: true,
            isLoadingToggle: this.setIsLoading,
            wilds: {placeId: this.model.id}
        })
        if (!response.isValid()) {
            RouterNavigationUtils.pushToError()
            return
        }
        RouterNavigationUtils.pushToPlaceShow(place.id!!)
    }
}