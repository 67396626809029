import React from "react";

const SupporterImage = (props:{
    alt?: string,
    image: string,
    url: string
}) => {
    return <a className={'single-supporter'} href={props.url}>
        <img src={props.image} alt={props.alt}/>
    </a>
}
export {SupporterImage};