import React, {useEffect} from "react"
import {useLocation} from "react-router"
import {CurrentUserState} from "../../state/CurrentUserState"

const LoginViaLink: React.FC = () => {

    let searchParams = new URLSearchParams(useLocation().search)
    let loginToken = searchParams.get("loginToken") as string

    useEffect(()=>{
        CurrentUserState.loginViaLink(loginToken)
    }, [])

    return <div>
        ...Loading
    </div>
}

export {LoginViaLink}