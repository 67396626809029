import React from "react";
import {Place} from "../../../../model/Place";
import {Address} from "../../../../model/Address";
import {ModelCollection} from "../../../../libs/frontmodel/src";

const DetailContactInfo: React.FC<{ place: Place }> = ({place}) => {

    const address = place.address!

    return <div className={'grid-contact'}>
        <div className={'address-data'}>
            <span>
                {place.name}<br/>
                {address.street} {address.number}<br/>
                {address.zipCode} {address.city}
            </span>
        </div>
        <div className={`contact-data${address.getFacebookLink() || address.getInstagramLink() ? ' social-avl': ''}`}>
            <div className={'titles'}>
                <span>Tel.:</span>
                <span>E-Mail:</span>
                {address.website
                    && <span>Web:</span>
                }
            </div>
            <div className={'contact-data-values'}>
                <span><a href={`tel:${address.phone}`}>{address.phone}</a></span>
                <span><a href={`mailto:${address.email}`} target={'_blank'}>{address.email}</a></span>
                {address.website
                    && <span>
                        <a href={address.getWebsite()} target={'_blank'}>{address.getWebsite()}</a>
                    </span>
                }
            </div>
            <div className={'social-icons-absolute'}>
            {address.getFacebookLink() &&
                <span className={'detail-social-icon'}>
                    <a href={address.getFacebookLink()} target={'_blank'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                            <path fill="#FFF" d="M17.345.714H7.201C3.761.714.971 3.5.971 6.934V17.06c0 3.434 2.79 6.219 6.23 6.219h10.144c3.44-.003 6.227-2.786 6.23-6.22V6.934c0-3.434-2.79-6.219-6.23-6.219zm4.404 16.32c-.003 2.441-1.984 4.419-4.43 4.422h-2.623v-7.523h3.54l.339-2.82h-3.879V8.715c-.032-.233.04-.469.2-.642.16-.174.388-.268.623-.257h3.01V5.09h-2.871c-3.184 0-3.91 2.382-3.91 3.902v2.126H9.907v2.825h1.867v7.513H7.227c-2.445-.003-4.427-1.981-4.43-4.422V6.96c.003-2.44 1.985-4.419 4.43-4.422H17.32c2.445.003 4.426 1.981 4.429 4.422v10.075z" />
                          </svg>
                    </a>
                </span>
            }
            {address.getInstagramLink() &&
                <span className={'detail-social-icon'}>
                    <a href={address.getInstagramLink()} target={'_blank'}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
                        <g fill="#FFF">
                          <path d="M16.374 0H6.23C2.79 0 0 2.784 0 6.219v10.127c0 3.434 2.79 6.218 6.23 6.218h10.144c3.44-.002 6.227-2.785 6.23-6.218V6.219c0-3.435-2.79-6.219-6.23-6.219zm4.403 16.32c-.003 2.44-1.984 4.419-4.429 4.421H6.255c-2.445-.002-4.426-1.98-4.429-4.421V6.245c.003-2.441 1.984-4.42 4.43-4.422h10.092c2.445.003 4.426 1.98 4.43 4.422V16.32z" transform="translate(.5 .714)" />
                          <path d="M11.6 5.077c-3.277-.008-5.94 2.637-5.95 5.908-.008 3.271 2.642 5.93 5.919 5.938 3.277.009 5.94-2.636 5.949-5.907.005-1.572-.616-3.08-1.726-4.195-1.11-1.114-2.618-1.741-4.192-1.744zm0 9.785c-1.568.006-2.984-.932-3.587-2.377-.603-1.444-.273-3.109.836-4.215 1.108-1.107 2.776-1.436 4.223-.835 1.447.602 2.387 2.016 2.38 3.58-.008 2.121-1.728 3.839-3.852 3.847zM19.213 4.79c.002.57-.34 1.086-.868 1.306-.528.22-1.137.1-1.542-.302-.405-.403-.527-1.01-.308-1.537.218-.528.733-.872 1.305-.872.374-.002.734.146.999.41.265.263.414.621.414.995z" transform="translate(.5 .714)" />
                        </g>
                      </svg>
                    </a>
                </span>
            }
            </div>
        </div>
    </div>
}

export {DetailContactInfo}