import React from "react"
import { IFormableProps } from "../libs/formhandling/IFormableProps"

const PlainInput = (props: IFormableProps & {
        labelText: string,
        placeholderText?: string,
        type?: "email" | "password" | "text",
        isRequired?: boolean,
        validateOnChange?: boolean,
        lastInputField?: boolean,
    }
) => {
    const formState = props.formState.use()
    const model = props.formModel as any
    const errors = model.getErrorsFor(props.property) 
    const isValid = !errors ? 'is-valid' : 'is-invalid'
    const inputType = props.type ?? "text"
    const placeHolder = props.placeholderText

    return <div className={`plain-input ${inputType} ${props.lastInputField ? 'last-input-field' : ''}`}>
        <label>
            <span>{props.labelText}</span>
        </label>
        <input 
            value={model[props.property]}
            type={inputType}
            placeholder={placeHolder}
            className={`${isValid} form-control `}
            onChange={(e)=>{
                model[props.property] = e.target.value
                if (props.validateFun) {
                    props.validateFun(model, props.property, e.target.value)
                }
                formState.triggerUpdate()
            }}
        />

        {errors &&
            <div className={'invalid-feedback error-message'}>{errors[0]}</div>
        }
    </div>

}

export {PlainInput}
