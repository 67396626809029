import React from "react";
import {OverlayMessage} from "../overlay/OverlayMessage";
import {DefaultButton} from "../button/DefaultButton";
import {SearchRedirectingSearchBar} from "../../../place/SearchReiderctingSearchBar";
import {Link} from "react-router-dom"

const MainBanner: React.FC<{
    image: string,
    imageTablet:string,
    imageMobile: string,
    buttonUrl?: string,
    buttonText: string,
    showButtonIcon: boolean,
    showSearch?: boolean,
    overlayMessage?: string,
    overlayUrl?:string,
    logoPath?:string,
    HashTagText?: string[]
}> = (props) => {


    const css = `
        .main-banner{
            background-image: url(${props.imageMobile});
        }
        @media(min-width: 480px) {
            .main-banner{
                background-image: url(${props.imageTablet});
            }
        }
        @media(min-width: 1000px) {
            .main-banner{
                background-image: url(${props.image});
            }
        }
    `;


    return <>

        <style>{css}</style>

        <div className="main-banner">
            {props.overlayMessage &&
                <OverlayMessage message={props.overlayMessage} overlayUrl={props.overlayUrl}/>
            }
            {props.logoPath &&
                <Link to="/places">
                    <img className={"logo"} src={"/public/img/svg/logo.svg"} alt="local-foodlovers"/>
                </Link>
            }
            {props.buttonUrl &&
                <DefaultButton btnCls={'btn'} urlDirection={props.buttonUrl}
                               buttonText={props.buttonText}
                               showIconRight={props.showButtonIcon}
                />
            }
            {props.showSearch &&
            <div className={'global-search'}>
                <SearchRedirectingSearchBar/>
            </div>
            }
            {props.HashTagText?.map((it,index)=>{
                return <p className={'subline'} key={index}>{it}</p>
            })}
        </div>
    </>
}
export {MainBanner};