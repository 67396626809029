import {ModelCollection} from "../../libs/frontmodel/src"
import {Place} from "../../model/Place"
import React from "react"
import { connectHits } from "react-instantsearch-dom"
import {PlaceHitShow} from "./PlaceHitShow"
import {AdditionalSearchState} from "../../state/AdditionalSearchState"

const Hits: React.FC<{hits: object[]}> = ({hits}) => {

    const places = new ModelCollection<Place>(...hits.map((it)=>{return new Place(it)}))

    if (!hits.length) {
        AdditionalSearchState.instance.setNoSearchResults(true)
        return <h1 className={'no-results'}>Leider keine Ergebnisse...</h1>
    }

    if (AdditionalSearchState.instance.noSearchResults) {
        AdditionalSearchState.instance.setNoSearchResults(false)
    }

    return <>
        {places.map((it)=>{
            return <PlaceHitShow place={it} key={it.id}/>
        })}
    </>

}

const PlaceHits = connectHits(Hits as any)

export {PlaceHits}