import React from "react";
import {Link} from "react-router-dom";

const Footer: React.FC = () => {
    return <footer>
        <img className={"logo-footer"} src={"/public/img/svg/logo-weiss.svg"}/>
        <p className={"copyright"}>
            Copyright © 2020 Zwiesel Kristallglas AG – Alle Rechte vorbehalten. Local Foodlovers ist ein Projekt von Zwiesel Kristallglas AG (<a href={"https://unternehmen.zwiesel-kristallglas.com/de/cms/contact/imprint_privacy.html"} target={'_blank'}><span>Impressum</span></a> / <a href={"https://unternehmen.zwiesel-kristallglas.com/de/cms/contact/privacy.html"} target={'_blank'}><span>Datenschutz</span></a>)<br/>und active value GmbH (<a href={'https://www.active-value.de/impressum/'} target={'_blank'}><span>Impressum</span></a> / <a href={" https://www.active-value.de/datenschutz/"} target={'_blank'}><span>Datenschutz</span></a>)
        </p>
        <div className={"social-row"}>
            <a href={"mailto:support@local-foodlovers.de"} className={"btn white"}>
                Kontakt
            </a>
            <a href={"https://www.facebook.com/localfoodloversgermany/"} target={"_blank"} className={"social-icon facebook"}>
                <img src="/public/img/svg/facebook.svg" alt="Facebook Local Foodlover"/>
            </a>
            <a href={'https://www.instagram.com/local_foodlovers/'} target={"_blank"} className={"social-icon instagram"}>
                <img src={"/public/img/svg/instagram.svg"} alt={"Instagram Local Foodlover"}/>
            </a>
        </div>
        <div className={'privacy-row'}>
            <span>Hier finden Sie die <Link to={"/agb"}><span>AGB</span></Link></span>
        </div>
    </footer>
}
export {Footer};