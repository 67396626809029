import React from "react"
import {IFormableProps} from "../libs/formhandling/IFormableProps"

const ValueBasedCheckbox = (props: IFormableProps & {
                                placeholderText: string | any,
                                isRequired?: boolean,
                                valueOnChecked: Boolean,
                                valueOnUnchecked: Boolean,
                                image?: string,
                                imageAltTag?: string,
                                onChange?: (value: any) => any
                            }
) => {
    const formState = props.formState.use()
    const model = props.formModel as any
    const errors = model.getErrorsFor(props.property)
    const isValid = !errors ? 'is-valid' : 'error'
    const placeHolder = props.placeholderText
    const isChecked = model[props.property] === props.valueOnChecked

    return <div className={"checkbox-element single"}>
        <span className={`select-box-item boxed${isChecked ? ' selected' : ''}`}
              onClick={(e) => {
                  if (!isChecked) {
                      model[props.property] = props.valueOnChecked
                  } else {
                      model[props.property] = props.valueOnUnchecked
                  }
                  props.validateFun?.(model)
                  props.onChange?.(model[props.property])
                  formState.triggerUpdate()
              }}
        >
            {placeHolder &&
            <span className={'placeholder'}>
                    {placeHolder}
                </span>
            }
            {props.imageAltTag &&
            <span className={'icon'}>
                {props.image &&
                  <img src={props.image} alt={props.imageAltTag}/>
                }
                {!props.image &&
                    <span className={'payment-name'}>{props.imageAltTag}</span>
                }
            </span>
            }
        </span>

        {errors &&
            <div className={'invalid-feedback error-message'}>{errors[0]}</div>
        }
    </div>

}

export {ValueBasedCheckbox}
