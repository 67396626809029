import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"
import {updatesSubscribers} from "../libs/statemanagement/decorators/updatesSubscribers"

export interface  IFlashMessage {
    type: "OK" | "WARNING",
    messageText: string
}

export class FlashNotificationState extends SubscriptionState {

    static instance = new FlashNotificationState()

    currentMessage?: IFlashMessage

    @updatesSubscribers()
    addMessage(type: "OK" | "WARNING", messageText: string, fast = false) {
        this.currentMessage = {
            type,
            messageText
        }
        setTimeout(()=>{
            this.removeMessage()
        }, fast ? 2000 : 4000)
    }

    @updatesSubscribers()
    removeMessage() {
        this.currentMessage = undefined
    }

    addInvalidFormMessage() {
        this.addMessage("WARNING", 'Formular enthält ungültige Eingaben', true)
    }

    addSuccessMessage() {
        this.addMessage('OK', 'Erfolg', true)
    }
}