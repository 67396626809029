import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"
import {User} from "../model/User"
import {RouterNavigationUtils} from "../utils/routing/RouterNavigationUtils"

export class CurrentUserState extends SubscriptionState {

    user?: User

    static loginViaLink = async (loginToken: string) => {
        const responseUser = await User.loginViaLink({queryParams: {loginToken}})
        console.log(responseUser)
        let placeId = responseUser.places?.array?.[0]?.id
        console.log(placeId)
        if (!placeId) {
            RouterNavigationUtils.pushToError()
            return
        }
        RouterNavigationUtils.pushToPlaceEdit(placeId)
    }

}