import React from "react";

const AgbPage = () => {
    return <>
        <div className={'main-body'}>
            <div className={'container'}>
                <h1>AGB</h1>
                <div className={'text-section'}>
                    <p>
                        „Local Foodlovers“ (LFL) ist lediglich Vermittler und stellt deshalb lediglich eine
                        unverbindliche
                        Möglichkeit der Kontaktanbahnung zwischen Restaurant und Kunde her. Der Kunde erkennt insoweit
                        an,
                        dass LFL lediglich als Vermittler auftritt. LFL haftet nicht für die Leistungserbringung des
                        Restaurants, die Richtigkeit oder Vollständigkeit der Restaurantinformation oder die
                        Durchführung
                        des zwischen Kunde und Restaurant geschlossenen Vertrages. Alle Ansprüche gegen LFL oder die
                        Partner
                        des Projekts (Zwiesel AG/active value GmbH), gleich welcher Art und aus welchem Rechtsgrund,
                        sind
                        vollständig ausgeschlossen, insbesondere auf Schadensersatz wegen Sach- und Rechtsmängeln aus
                        oder
                        im Zusammenhang mit dem Vertrag oder wegen Verletzung vorvertraglicher Pflichten. Dies gilt
                        nicht in
                        den Fällen von Vorsatz oder grober Fahrlässigkeit oder aufgrund Verletzung des Lebens, des
                        Körpers
                        oder der Gesundheit, soweit diese unmittelbar aus der Vermittlung von LFL resultieren. Der
                        Anspruch
                        ist der Höhe nach auf den unmittelbaren, typischen und vorhersehbaren Schaden beschränkt. Sofern
                        und
                        soweit ein Kunde Ansprüche aus oder im Zusammenhang mit dem durch LFL zwischen Kunde und
                        Restaurant
                        vermittelten Vertrag gegen LFL geltend macht, stellt das Restaurant LFL und die Projektpartner
                        unbedingt und vorbehaltlos sowie vollständig frei.
                    </p>
                    <p>
                        LFL ist berechtigt, Restaurants von der Plattform auszuschließen, soweit Angaben des Restaurants
                        unzutreffend sind, Zweifel an der Richtigkeit oder Vollständigkeit der Angaben bestehen oder
                        sonst nach Auffassung von LFL wesentliche Gründe für den Ausschluss vorliegen.
                    </p>
                    <p>
                        Das auf der Plattform veröffentlichte Angebot wird ausschließlich auf Basis der von dem
                        Restaurant zur Verfügung gestellten Angaben bereitgestellt. LFL übernimmt keinerlei Gewähr für
                        die Richtigkeit und/oder Vollständigkeit der vom Restaurant gemachten oder bereitgestellten
                        Informationen oder Angaben oder die Lieferfähigkeit des Restaurants, die Qualität der Speisen
                        oder Art und Inhalt der Inhaltsstoffe. Der Vertrag kommt unmittelbar zwischen Kunde und
                        Restaurant zustande; für die Bestellung des Kunden gelten die Geschäftsbedingungen des
                        jeweiligen Restaurants, das ausschließlich für die ordnungsgemäße Leistungserbringung gegenüber
                        dem Kunden verantwortlich ist. LFL übernimmt keine Haftung für die Bonität von Kunden oder die
                        Nichterfüllung von zwischen Kunde und Restaurant geschlossenen Verträgen.
                    </p>
                    <p>
                        Im Hinblick auf die bei Anmeldung des Restaurants erhobenen, für die Teilnahme notwendigen Daten
                        gelten die Regelungen zum Datenschutz der active Value GmbH. Ergänzend hierzu stimmen Sie mit
                        Anmeldung Ihres Restaurants zu, dass die abgegebenen, verpflichtenden Angaben für die
                        Kommunikation zur Organisation des Projektes LFL, zur Koordination der Kontaktanbahnung mit
                        Kunden und zum Erfahrungsaustausch zwischen Restaurants, den Projektpartnern und Beteiligten
                        verwendet werden dürfen. Sie stimmen auch zu, dass die Projektpartner (Zwiesel AG/active value
                        GmbH) während und nach der Dauer des Projektes LFL die Restaurants und Inhaber mit Informationen
                        zum Projekt, Dienstleistungsofferten und Produktvorschlägen kontaktieren dürfen. Die ergänzende
                        Zustimmung im vorgenannten Sinne kann jederzeit schriftlich gegenüber einem der Projektpartner
                        Zwiesel AG/active value GmbH widerrufen werden. Hierfür genügt eine einfache Mitteilung per
                        Email.
                    </p>
                </div>
            </div>
        </div>
    </>
}
export {AgbPage}