import {BaseModel, Property} from "../libs/frontmodel/src";
import {Place} from "./Place";
import {getHours, getMinutes} from 'date-fns';

export const dayNameToNumberMap = {
    "MO": 0,
    "TUE": 1,
    "WED": 2,
    "THUR": 3,
    "FR": 4,
    "SAT": 5,
    "SUN": 6,
}

export class OpenTime extends BaseModel {

    @Property
    id?: number

    @Property
    day?: string

    @Property
    readableName?: string

    @Property
    from?: number | null

    @Property
    fromExtra?: number | null

    @Property
    to?: number | null

    @Property
    toExtra?: number | null

    @Property
    placeId?: number

    @Property
    place?: Place

    @Property
    openThisDay?: boolean

    getHumanReadableName: ()=>string = () => {
        return dayToHumanReadableMap[this.day as keyof typeof dayToHumanReadableMap]
    }

    getFormattedFrom = () => {
        return getFormattedIntAsHours(this.from)
    }

    getFormattedTo = () => {
        return getFormattedIntAsHours(this.to)
    }
    getFormattedExtraFrom = () =>{
        return getFormattedIntAsHours(this.fromExtra)
    }
    getFormattedExtraTo = () => {
        return getFormattedIntAsHours(this.toExtra)
    }

    getDayNumber = () => {
        let day = this.day as keyof typeof dayNameToNumberMap
        return dayNameToNumberMap[day]
    }

    isOpenNow = () => {
        const date = Date.now()
        const hours = getHours(date)
        const minutesCount = (hours * 60) + getMinutes(date)
        if (this.from! < minutesCount && this.to! > minutesCount) {
            return true
        }
        if (this.fromExtra! < minutesCount && this.toExtra! > minutesCount) {
            return true
        }
        return false
    }

}

const getFormattedIntAsHours = (value?: number|null) => {
    if (value !== 0 && !value) {
        return
    }
    let hours = `${Math.floor(value / 60)}`
    let minutes = `${value % 60}`
    if (hours.length < 2) {
        hours = `0${hours}`
    }
    if (minutes.length < 2) {
        minutes = `0${minutes}`
    }
    return `${hours}:${minutes}`
}

const dayToHumanReadableMap = {
    "MO": "Montag",
    "TUE": "Dienstag",
    "WED": "Mittwoch",
    "THUR": "Donnerstag",
    "FR": "Freitag",
    "SAT": "Samstag",
    "SUN": "Sonntag"
}