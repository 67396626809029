import React from "react";

import {MainBanner} from "../components/layouts/layoutelements/mainbanner/MainBanner";
import {Blog} from "../components/layouts/layoutelements/blog/Blog";
import {IntroductionSection} from "../components/layouts/layoutelements/icon-text-block/IntroductionSection";
import {Supporter} from "../components/layouts/layoutelements/supporterlogos/Supporter";

const StartPage = () => {
    return <>
        <MainBanner
            image={'/public/img/banner/header-desktop@2x.png'}
            imageTablet={'/public/img/banner/header-desktop.png'}
            imageMobile={'/public/img/banner/header-mobile.png'}
            buttonText={''}
            showButtonIcon={false}
            overlayMessage={'Kostenlose Anmeldung für Restaurants, Bars und Winzer'}
            overlayUrl={'/registrieren'}
            logoPath={'/public/img/svg/logo.svg'}
            HashTagText={['#supportyourlocalrestaurants', '#supportyourlocalbar', '#supportyourlocalwinery']}
            showSearch={true}
        />
        <IntroductionSection/>
        <Supporter/>
        <Blog/>
    </>
}
export {StartPage}