import React from "react"
import { connectStats } from "react-instantsearch-dom"
import {PlaceSearchState} from "../../state/PlaceSearchState"
import {AdditionalSearchState} from "../../state/AdditionalSearchState"

const Stats: React.FC<{
    processingTime: any,
    nbHits: number
}> = ({nbHits}) => {

    const searchState = AdditionalSearchState.instance.use()

    return <div className="stats">
        {(nbHits > 0 && !searchState.currentCity)
            && <p>{`${nbHits} Foodlovers`}</p>
        }
        {(nbHits > 0 && searchState.currentCity)
            && <p>{`${searchState.currentCity} ${nbHits} Foodlovers`}</p>
        }
    </div>
}

const PlaceStats = connectStats(Stats as any)

export {PlaceStats}
