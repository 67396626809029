import {ModelRegistry} from "../libs/frontmodel/src";
import {Place} from "./Place";
import {OpenTime} from "./OpenTime";
import {OrderOption} from "./OrderOption";
import {PaymentType} from "./PaymentType";
import {Media} from "./Media";
import {Address} from "./Address";
import {Category} from "./Category";
import {User} from "./User"

export class ModelRegistrator {
    static run() {
        ModelRegistry.registeredModels = {
            Place,
            OpenTime,
            OrderOption,
            PaymentType,
            Media,
            Address,
            Category,
            User,
        }
    }
}