import {SubscriptionState} from "../libs/statemanagement/SubscriptionState"

export class AdditionalSearchState extends SubscriptionState {

    static instance = new AdditionalSearchState()

    currentCity?: string

    noSearchResults =  false

    setCurrentCity = (city?: string) => {
        this.currentCity = city
        this.triggerUpdate()
    }

    setNoSearchResults = (value: boolean) => {
        this.noSearchResults = value
        this.triggerUpdate()
    }
    cleanup = () => {
        this.currentCity = undefined
    }
}
