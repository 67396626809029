import { connectAutoComplete } from "react-instantsearch-dom"
import React, {useEffect} from "react"
import {Place} from "../../model/Place"
import {PlaceSearchState} from "../../state/PlaceSearchState"
import {AdditionalSearchState} from "../../state/AdditionalSearchState"

const AutoComplete: React.FC<{hits: Place[], currentRefinement: string, refine: (value: any)=>any}> = ({hits, currentRefinement, refine}) => {

    useEffect(()=>{
        if (PlaceSearchState.instance.initialQuery) {
            refine(PlaceSearchState.instance.initialQuery)
        }
    }, [])

    if (!isNaN(currentRefinement as any)) {
        AdditionalSearchState.instance.setCurrentCity(getCurrentZipCode(hits))
    } else {
        AdditionalSearchState.instance.setCurrentCity(getCity(hits))
    }
    return <div className={'search'}>
        <input type="search" placeholder={'PLZ, Ort oder Restaurant'}
            value={currentRefinement}
            onChange={event => refine(event.currentTarget.value)}
        />
        <button className={'btn'}>Suchen</button>
    </div>
}

const getCity = (hits: Place[]): string | undefined => {
    let cities = hits ?? []
    const citiesMap: {[id:string]: number} = {}
    cities.forEach((it)=>{
        let city = it.address?.city
        if (!city) {
            return undefined
        }
        let numOccurences = citiesMap[city] ?? 0
        numOccurences += 1
        citiesMap[city] = numOccurences
    })
    let keys = Object.keys(citiesMap)
    if (keys.length == 1) {
        return keys[0]
    }
    return undefined
}

const getCurrentZipCode = (hits: Place[]): string | undefined => {
    let zipCodes = hits ?? []
    const zipCodeMap: {[id:number]: number} = {}
    zipCodes.forEach((it)=>{
        let zipCode = it.address?.zipCode!
        if (!zipCode) {
            return undefined
        }
        let numOccurences = zipCodeMap[zipCode as any] ?? 0
        numOccurences += 1
        zipCodeMap[zipCode as any] = numOccurences
    })
    let keys = Object.keys(zipCodeMap)
    if (keys.length == 1) {
        return `${hits[0]?.address?.zipCode}, ${hits[0]?.address?.city}` as any
    }
    return undefined
}

const PlaceByAddressSearchBar = connectAutoComplete(AutoComplete as any)

export {PlaceByAddressSearchBar}

