import React from "react"
import {FlashNotificationState} from "../../../../state/FlashNotificationState"
import classNames from "classnames"

const FlashNotifications: React.FC = () => {

    const flashNotificationState = FlashNotificationState.instance.use()

    const currentMessage = flashNotificationState.currentMessage

    return <div className={classNames(`flash-notifications`, {'active': !!flashNotificationState.currentMessage})}>
        {currentMessage &&
            <h1 className={classNames(`notification-type ${currentMessage?.type}`)}>
                <span className={'icon'}></span><span className={'flashmessage'}>{currentMessage?.messageText}</span>
            </h1>
        }
    </div>
}

export {FlashNotifications}