import {FormState} from "../libs/formhandling/FormState";
import {BaseModel, ModelCollection} from "../libs/frontmodel/src";
import {OpenTime} from "../model/OpenTime";
import {ISelectPair} from "../formelements/SingleSelectInput";
import {Place} from "../model/Place";
import {Media} from "../model/Media";
import {IMiniCheckboxGroupSelect} from "../formelements/MultiCheckboxGroupInput";
import {OrderOption} from "../model/OrderOption";
import {PaymentType} from "../model/PaymentType";
import {asKeyOf} from "../libs/typeutils/asKeyOf";
import {Address} from "../model/Address";
import {Category} from "../model/Category";
import {RouterNavigationUtils} from "../utils/routing/RouterNavigationUtils";


export const availablePaymentTypes = (): IMiniCheckboxGroupSelect[] => {
    return [
        {
            systemName: "AMAZON_PAY",
            name: "Amazon Pay",
        },
        {
            systemName: "CASH",
            name: "Bar"
        },
        {
            systemName: "EC_CARD",
            name: "EC",
            image: '/public/img/payments/EC.svg'
        },
        {
            systemName: "KLARNA",
            name: "Klarna",
            image: '/public/img/payments/klarna-online.png'
        },
        {
            systemName: "APPLE_PAY",
            name: "Applepay",
        },
        {
            systemName: "PAYPAL",
            name: "PayPal",
            image: '/public/img/payments/PayPal.png'
        },
        {
            systemName: "CREDIT_CARD",
            name: "Kreditkarte"
        },
        {
            systemName: "MOBILE_PAY",
            name: "Mobile Pay",
            image: '/public/img/payments/mobile-pay.svg'
        }
    ].map((it)=>{
        let pair: IMiniCheckboxGroupSelect = {
            placeHolder: it.name,
            value: new PaymentType({systemName: it.systemName}),
            image: it.image
        }
        return pair
    })
}

export const getOrderOptionsForSelect = (): IMiniCheckboxGroupSelect[] => {
    return [
        {
            type: "PICKUP",
            readableName: "Abholung",
            required: "true",
        },
        {
            type: "DELIVERY",
            readableName: "Lieferservice",
            required: "true"
        },
        {
            type: "MENU_OFFERS",
            readableName: "Menüangebote"
        },
        {
            type: "DRINKS_SERVICE",
            readableName: "Getränkeservice"
        }
    ].map((it) => {
        let pair: IMiniCheckboxGroupSelect = {
            placeHolder: it.readableName,
            value: new OrderOption({type: it.type, required: it.required}),
        }
        return pair
    })
}

export const getDefaultOpenTimes = () => {
    let openTimes = new ModelCollection<OpenTime>()
    let days = [
        ["Montag", "MO"], ["Dienstag", "TUE"], ["Mittwoch", "WED"],
        ["Donnerstag", "THUR"], ["Freitag", "FR"], ["Samstag", "SAT"], ["Sonntag", "SUN"]
    ].map(( [readableName, day])=>{
        let openTime = new OpenTime()
        openTime.day = day
        openTime.from = null
        openTime.to = null
        openTime.toExtra = null
        openTime.fromExtra = null
        openTime.openThisDay = false
        openTime.readableName = readableName
        openTimes.array.push(openTime)
    })
    return openTimes
}


export const getWorkingHoursForSelect = () => {
    let max = 24
    let result = []
    //result.push({value: null, humanReadableName: '-'})
    for (let i = 0; i < max; i++) {
        result.push({value: i, humanReadableName: `${i}`})
    }
    return result
}

export const getCategoriesForSelect: ISelectPair[] = ["Afrikanisch", "Ägyptisch", "Albanisch", "Amerikanisch", "Arabisch", "Asiatisch", "Bar",
    "Chinesisch", "Europäisch", "Glutenfrei", "International", "Koreanisch", "Indonesisch", "Japanisch", "Thailändisch", "Vietnamesisch", "Belgisch", "Brasilianisch",
    "Bulgarisch", "Bürgerlich", "Deutsch / Regional", "Englisch", "Französisch", "Georgisch", "Griechisch", "Halal", "Holländisch / Niederländisch",
    "Indisch", "Israelisch", "Italienisch", "Kroatisch", "Koscher" ,"Marokkanisch", "Mexikanisch", "Modern", "Österreichisch", "Orientalisch", "Polnisch", "Rumänisch",
    "Russisch", "Serbisch", "Skandinavisch", "Spanisch", "Südamerikanisch", "Tunesisch", "Türkisch", "Tschechisch", "Ukrainisch", "Ungarisch", "Vegan", "Winzer", "Weinhandel", "Vegetarisch"
].map((it)=>{
    return {
        value: it,
        humanReadableName: it
    }
})

export class NewPLaceFormState extends FormState {

    model!: Place

    static ORDER_OPTIONS_CHECKED_NAME = 'orderOptionsChecked'

    static PAYMENT_TYPE_SELECTION = "paymentTypeSelection"

    orderOptionsForSelect!: IMiniCheckboxGroupSelect[]

    paymentTypesForSelect!: IMiniCheckboxGroupSelect[]

    workingHoursForSelect!: ISelectPair[]

    isLoading: Boolean = false

    static init() {
        let place = new Place()
        place.menuMedia = new Media()
        place.imageMedia = new Media()
        place.logoMedia = new Media()
        place.openTimes = getDefaultOpenTimes()
        place.address = new Address()
        let formSate = new NewPLaceFormState(place)
        formSate.orderOptionsForSelect = getOrderOptionsForSelect()
        formSate.paymentTypesForSelect = availablePaymentTypes()
        formSate.workingHoursForSelect = getWorkingHoursForSelect()
        return formSate
    }

    save = async () => {
        if (this.isLoading) {
            return
        }
        const place = this.model
        this.validate()
        if (!place.isValid()) {
            this.triggerUpdate()
            return
        }
        const response = await place.create({serializeAsForm: true, isLoadingToggle: this.setIsLoading})
        if (!response.isValid()) {
            RouterNavigationUtils.pushToError()
            return
        }
        RouterNavigationUtils.pushToNewPlaceSuccess()
    }

    validate = () => {
        const place = this.model
        this.validateTosAccepted(place)
        this.validatePlaceName(place)
        this.validatePlaceImageMedia(place.imageMedia!)
        this.validatePlaceMenuMedia(place.menuMedia!)
        this.validatePlaceLogoMedia(place.logoMedia!)
        const address = place.address!
        this.validateAddressCity(address)
        this.validateAddressZipCode(address)
        this.validateAddressStreet(address)
        this.validateAddressNumber(address)
        this.validateAddressEmail(address)
        this.validateAddressPhoneNumber(address)
        this.validatePlacePaymentTypesPresence(place)
        this.validateAtLeastOneRequiredOrderOptionSelected(place)
        this.validateWebsite(address)
        this.validateCategories(place)
    }

    setIsLoading = (value: Boolean) => {
        this.isLoading = value
        this.triggerUpdate()
    }

    validatePlaceImageMedia = (media: Media) => {
        media.validate()
        let file = media.file ?? null
        if (!file) {
            return
        }
        const errors = this.validateFile(file, 1024 * 1000 * 2, ['img', 'png', 'jpeg', 'jpg'])
        if (errors) {
            errors.forEach((it)=>{
                media.addErrorFor('file', it)
            })
            media.file = "" as any
        }
    }

    validatePlacePaymentTypesPresence = (place: Place) => {
        let message = "Mindestens eine Option muss ausgewählt werden"
        place.removeErrorsFor(NewPLaceFormState.PAYMENT_TYPE_SELECTION)
        if (!place.paymentTypes.array.length) {
            place.addErrorFor(NewPLaceFormState.PAYMENT_TYPE_SELECTION, message)
        }
    }

    validatePlaceMenuMedia = (media: Media) => {
        media.validate()
        let file = media.file ?? null
        if (!file) {
            return
        }
        const errors = this.validateFile(file, 1024 * 1000 * 30, ['pdf'])
        if (errors) {
            errors.forEach((it)=>{
                media.addErrorFor('file', it)
            })
            media.file = "" as any
        }
    }

    validateTosAccepted = (place: Place) => {
        place.removeErrorsFor('tosAccepted')
        if (!place.tosAccepted) {
            place.addErrorFor('tosAccepted', 'Sie müssen die AGB akzeptieren')
        }
    }

    validateAddressEmail = (address: Address) => {
        let property = asKeyOf<Address>('email')
        address.removeErrorsFor(property)
        let email = address.email
        this.validateInputNotEmpty(address, property, email)
        if (email && !email.match(/^\S+@\S+$/)) {
            address.addErrorFor(property, 'ungültig')
        }
    }

    validateAddressPhoneNumber = (address: Address) => {
        let property = asKeyOf<Address>('phone')
        address.removeErrorsFor(property)
        this.validateInputNotEmpty(address, property, address.phone)
        if (address.phone && !address.phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
            address.addErrorFor(property, 'ungültig')
        }
    }

    validatePlaceLogoMedia = (media: Media) => {
        media.removeErrorsFor("file")
        let file = media.file ?? null
        if (!file) {
            return
        }
        const errors = this.validateFile(file, 1024 * 1000, ['img', 'png', 'jpeg', "jpg"])
        if (errors) {
            errors.forEach((it)=>{
                media.addErrorFor('file', it)
            })
            media.file = "" as any
        }
    }

    validatePlaceName = (place: Place) => {
        this.validateInputNotEmpty(place, asKeyOf<Place>("name"), place.address)
    }

    validateAddressStreet = (address: Address) => {
        this.validateInputNotEmpty(address, asKeyOf<Address>("street"), address.street)
    }

    validateAddressNumber = (address: Address) => {
        this.validateInputNotEmpty(address, asKeyOf<Address>('number'), address.number)
    }

    validateAddressZipCode = (address: Address) => {
        let property = asKeyOf<Address>('zipCode')
        address.removeErrorsFor(property)
        this.validateInputNotEmpty(address, property, address.zipCode)
        let zipCode = address.zipCode
        if (zipCode && isNaN(zipCode as any)) {
            address.addErrorFor(property, 'ungültig')
        }
    }

    validateAddressCity = (address: Address) => {
        this.validateInputNotEmpty(address, asKeyOf<Address>('city'), address.city)
    }

    addOrderOption = (place: Place, orderOption: OrderOption) => {
        let assigned = place.orderOptions.array.find((it)=>{return it.type === orderOption.type})
        if (!assigned) {
            place.orderOptions.array.push(orderOption)
        }
        this.validateAtLeastOneRequiredOrderOptionSelected(place)
        this.triggerUpdate()
    }

    removeOrderOption = (place: Place, orderOption: OrderOption) => {
        place.orderOptions.array = place.orderOptions.array
            .filter((it)=>{return it.type !== orderOption.type})
        this.validateAtLeastOneRequiredOrderOptionSelected(place)
        this.triggerUpdate()
    }

    isOrderOptionSelected = (place: Place, orderOption: OrderOption) => {
        let assigned = place.orderOptions.array
            .find((it)=>{return it.type === orderOption.type})
        return !!assigned
    }

    addPaymentType = (place: Place, paymentType: PaymentType) => {
        let assigned = place.paymentTypes.array.find((it)=>{return it.systemName === paymentType.systemName})
        if (!assigned) {
            place.paymentTypes.array.push(paymentType)
        }
        this.validatePlacePaymentTypesPresence(place)
        this.triggerUpdate()
    }

    removePaymentType = (place: Place, paymentType: PaymentType) => {
        place.paymentTypes.array = place.paymentTypes.array
            .filter((it)=>{return it.systemName !== paymentType.systemName})
        this.validatePlacePaymentTypesPresence(place)
        this.triggerUpdate()
    }

    isPaymentTypeSelected = (place: Place, paymentType: PaymentType) => {
        let assigned = place.paymentTypes.array
            .find((it)=>{return it.systemName === paymentType.systemName})
        return !!assigned
    }

    validateFile = (file: File, maxSize: number, extensions: string[]) => {
        let errors = []
        if (!file) {
            return null
        }
        let name = file.name
        let splitted = name.split('.')
        let extension = splitted[splitted.length - 1]
        if (!extensions.includes(extension)) {
            errors.push('Bitte laden Sie die Datei mit folgenden Extensionen hoch: ' + extensions.join(', '))
        }
        if (file.size > maxSize) {
            errors.push('Die maximale Dateigröße muss kleiner sein als' + this.bytesToSize(maxSize))
        }
        if (errors.length) {
            return errors
        }
        return null
    }

    bytesToSize = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
        return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
    }

    validateAtLeastOneRequiredOrderOptionSelected = (place: Place) => {
        place.removeErrorsFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME)
        let atLeastOneRequiredIsAssigned = false
        let requiredInputsAvailable = false
        place.orderOptions?.forEach((it)=>{
            if (it.required) {
                atLeastOneRequiredIsAssigned = true
            }
        });
        this.orderOptionsForSelect.forEach((pair) => {
            if ((pair.value as OrderOption).required) {
                requiredInputsAvailable = true
            }
        })
        if (requiredInputsAvailable && !atLeastOneRequiredIsAssigned) {
            place.addErrorFor(NewPLaceFormState.ORDER_OPTIONS_CHECKED_NAME, 'Bitte wählen Sie mindestens ein erforderliches Feld aus')
        }
    }

    validateInputNotEmpty = (model: any, property: string, value: any) => {
        let message = "Muss ausgefüllt werden";
        (model as BaseModel).removeErrorsFor(property)
        value = model[property]
        if (!value || value === "") {
            model.addErrorFor(property, message)
        }
    }

    validateLink(model: any, property: string, value?: string) {
        let expr = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i');
        let message = "ungültig";
        (model as BaseModel).removeErrorsFor(property)
        value = model[property]
        if (value && !value.match(expr)) {
            model.addErrorFor(property, message)
        }
    }

    orderOptionIsValid = (place: Place, orderOption: OrderOption) => {
        let assigned = place.orderOptions.array.find((it)=>{return it.type === orderOption.type})
        console.log(!(!assigned && orderOption.required))
        return !(!assigned && orderOption.required)
    }

    validateWebsite = (address: Address) => {
        this.validateLink(address, asKeyOf<Address>("website"), address.website)
    }

    addCategory = (place: Place, name?: string) => {
        let exists = place.categories?.array?.find((it)=>{return it.name === name})
        if (exists) {
            return
        }
        let category = new Category()
        category.name = name
        place.categories.array.push(category)
        this.validateCategories(place)
    }

    removeCategory = (place: Place, name?: string) => {
        place.categories.array = place.categories.array.filter((it)=>{return it.name !== name})
        this.validateCategories(place)
    }

    isCategorySelected = (place: Place, name?: string) => {
        return !!place.categories.array.find((it)=>{return it.name === name})
    }

    validateCategories = (place: Place) => {
        let property = asKeyOf<Place>('categories')
        place.removeErrorsFor(property)
        if (!place.categories.array.length) {
            place.addErrorFor(property, "Mindestens ein Wert muss ausgewählt werden")
        }
    }

    validateEmailDoesNotExist = async (address: Address, email: string) => {
        if (!email) {
            return
        }
        if (email.length < 3) {
            return
        }
        let response = await Address.emailAlreadyExists({queryParams: {email: email}})
        if (response.email) {
            address.addErrorFor('Email', "E-mail already exists")
        }

    }

}