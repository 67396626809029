import React, {useEffect, useRef} from "react";

const ClickOutside: React.FC<{
    children: any, on: ()=>any
}> = ({children, on}) =>{

    const ref = useRef<any>(null)
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref?.current?.contains?.(event.target)) {
                on()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return <div ref={ref}>
        {children}
    </div>

}

export {ClickOutside}