import React from "react";

const IconText: React.FC<{icon?:string, text:string, title?:string}> = (props) => {
    return <>
        <div className={'block-icon-text'}>
            {props.icon &&
                <img src={props.icon}/>
            }
            {props.title &&
                <p className={'title'}>{props.title}</p>
            }
            <p>{props.text}</p>
        </div>
    </>
}
export {IconText}