import React from "react";
import {useCookies} from "react-cookie";
import {GaHandler} from "../../../../utils/tracking/GaHandler"


const Cookiebanner: React.FC = () => {

    const [cookies, setCookies] = useCookies(['AllowAllCookies','AllowOnlyTecCookies']);

    const getCookies = () => {
        if (!cookies.AllowAllCookies && !cookies.AllowOnlyTecCookies) {
            return true;
        }
    }
    const setCookieAccepted = () => {
        setCookies('AllowAllCookies', true, {path: '/', maxAge: 1209600})
        GaHandler.cookies = {...(cookies ?? {}), ...{AllowAllCookies: {}}}
        GaHandler.initialize()
    }
    const setTechnicalCookie = () => {
        setCookies('AllowOnlyTecCookies',true,{path:'/',maxAge: 1209600})
    }

    return <>
        {getCookies() &&
        <div className={`cookiebanner${cookies.AllowAllCookies || cookies.AllowOnlyTecCookies ? ' hidden' : ''}`}>
            <div className={"container"}>
                <div className={'button-row'}>
                    <div className={'btn white-filled'} onClick={setCookieAccepted}>Alle Cookies akzeptieren</div>
                    <div className={'btn black-filled'} onClick={setTechnicalCookie}>Nur technische Cookies
                        akzeptieren
                    </div>
                </div>
                <div className={'cookietext'}>
                    <p>Wir verwenden Cookies. Wir verwenden technisch notwendige Cookies. Durch das weitersurfen
                        erklärst du
                        dich damit einverstanden. Andere Cookies helfen uns dabei, dein Online-Erlebnis zu verbessern.
                        Weitere Informationen findest du in der <a href={"https://unternehmen.zwiesel-kristallglas.com/de/cms/contact/privacy.html"} target={'_blank'}>Datenschutzerklärung</a>.</p>
                </div>
            </div>
        </div>
        }
        </>
}
export {Cookiebanner};